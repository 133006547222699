import { react2angular } from "react2angular";
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { IIconProps, parseClassName } from './icons.misc'

export const ExclamationTriangle = (props: IIconProps) => {
    const className = parseClassName(props);
    return <ExclamationTriangleIcon className={className} />;
}

const ExclamationTriangleModule = angular
    .module('42.components.icons.exclamation-triangle', [])
    .component('exclamationTriangle', react2angular(ExclamationTriangle, ['model']));

export const EXCLAMATION_TRIANGLE_ICONS = {
    'exclamation-triangle': ExclamationTriangle,
}

export default ExclamationTriangleModule;
