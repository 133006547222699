import { react2angular } from "react2angular";
import { Bars3Icon } from '@heroicons/react/24/solid'
import { IIconProps, parseClassName } from "./icons.misc";

const Bars3 = (props: IIconProps) => {
    const className = parseClassName(props);
    return <Bars3Icon className={className} />;
}

const Bars3Micro = (props: IIconProps) => {
    const Bars3MicroIcon = (props: {className: string}) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={props.className} viewBox="0 0 16 16" fill="currentColor">
                <path fillRule="evenodd" d="M2 3.75A.75.75 0 0 1 2.75 3h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 3.75ZM2 8a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 8Zm0 4.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
            </svg>
        );
    }

    const className = parseClassName(props);
    return <Bars3MicroIcon className={className}/>;
}

const Bars3Module = angular
    .module('42.components.icons.bars-3', [])
    .component('bars3', react2angular(Bars3, ['model']))
    .component('bars3Micro', react2angular(Bars3Micro, ['model']));

export const BARS_3_ICONS = {
    'bars-3': Bars3,
    'bars-3-micro': Bars3Micro,
}

export default Bars3Module;
