import { react2angular } from "react2angular";
import { CheckIcon } from '@heroicons/react/24/solid'
import { IIconProps, parseClassName } from "./icons.misc";

const Check = (props: IIconProps) => {
    const className = parseClassName(props);
    return  <CheckIcon className={className} />;
}

const CheckModule = angular
    .module('42.components.icons.check', [])
    .component('check', react2angular(Check, ['model']));

export const CHECK_ICONS = {
    'check': Check,
}
export default CheckModule;
