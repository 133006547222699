import { react2angular } from "react2angular";
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import { IIconProps, parseClassName } from "./icons.misc";

const ClipboardDocumentList = (props: IIconProps) => {
    const className = parseClassName(props);
    return <ClipboardDocumentListIcon className={className} />;
}

const ClipboardDocumentListModule = angular
    .module('42.components.icons.clipboard-document', [])
    .component('clipboardDocumentList', react2angular(ClipboardDocumentList, ['model']));

export const CLIPBOARD_DOCUMENT_ICONS = {
    'clipboard-document-list': ClipboardDocumentList,
}
export default ClipboardDocumentListModule;
