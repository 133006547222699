import { react2angular } from "react2angular";
import { XMarkIcon } from '@heroicons/react/24/solid'
import { XMarkIcon as XMarkSmallIcon } from '@heroicons/react/16/solid'
import { IIconProps, parseClassName } from './icons.misc'

export const XMark = (props: IIconProps) => {
    const className = parseClassName(props);
    return <XMarkIcon className={className} />;
}
export const XMarkSmall = (props: IIconProps) => {
    const className = parseClassName(props);
    return <XMarkSmallIcon className={className} />;
}

const XMarkModule = angular
    .module('42.components.icons.x-mark', [])
    .component('markX', react2angular(XMark, ['model']))
    .component('markXSmall', react2angular(XMarkSmall, ['model']));

export const X_MARK_ICONS = {
    'x-mark': XMark,
    'x-mark-small': XMarkSmall,
}

export default XMarkModule;
