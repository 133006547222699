import { react2angular } from "react2angular";
import { ArrowUpTrayIcon } from '@heroicons/react/16/solid'
import { IIconProps, parseClassName } from "./icons.misc";

const ArrowUpTray = (props: IIconProps) => {
    const className = parseClassName(props);
    return <ArrowUpTrayIcon className={className} />;
}

const ArrowUpTrayModule = angular
    .module('42.components.icons.arrow-up-tray', [])
    .component('arrowUpTray', react2angular(ArrowUpTray, ['model']));

export const ARROW_UP_TRAY_ICONS = {
    'arrow-up-tray': ArrowUpTray,
}

export default ArrowUpTrayModule;
