import { react2angular } from "react2angular";
import { BellAlertIcon } from '@heroicons/react/24/solid'
import { IIconProps, parseClassName } from "./icons.misc";

const BellAlert = (props: IIconProps) => {
    const className = parseClassName(props);
    return  <BellAlertIcon className={className} />;
}

const BellAlertModule = angular
    .module('42.components.icons.bellAlert', [])
    .component('bellAlert', react2angular(BellAlert, ['model']));

export const BELL_ALERT_ICONS = {
    'bell-alert': BellAlert,
}
export default BellAlertModule;
