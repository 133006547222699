import _ from 'lodash'
import { getScrollbarWidth } from '../lib/dom/scroll'

module = angular.module '42.directives.supertable', []
module.directive 'supertable', ($compile) ->
    restrict: 'A'
    link: (scope, element) ->
        $element = $(element)
        $main    = $element.find('main')
        $header  = $element.find('header')
        $footer  = $element.find('footer')
        $header.css right:"#{getScrollbarWidth()}px"
        $footer.css right:"#{getScrollbarWidth()}px"

        rows = $header.find('tr').length
        headerHeight = rows * $header.height() + 2
        $header.height(headerHeight)
        $main.css top:"#{headerHeight}px"

        $headerCells = $header.find('.row-search th').has('input[type=search]')
        elem = $compile('<magnifying-glass-micro class="react-icon search-icon" model="\'light-grey icon-14\'"></magnifying-glass-micro>')(scope)
        $headerCells.append(elem)
        $headerCells.wrapInner('<div class="search-container"></div>')

export default module
