import _ from 'lodash'
import { preventOverscroll } from '../../../lib/dom/scroll'

module = angular.module('42.controllers.scheduling.common')


isTimeProperty = (propertyId) ->
    propertyId.startsWith('calendar.') or \
    propertyId.startsWith('calendar_periods.') or \
    propertyId.startsWith('transactions.timestamp__hour')


module.directive 'reportParamsSortFieldSelect', (UISelectDirective) ->  UISelectDirective (x) ->
    if x is 'selected'
        """
        <article class="field" ng-class="{first:$index && #{x}.group != available[$index-1].group}">
            <span class="group">{{ #{x}.group }}</span>
            <span class="label">{{ #{x}.label }}</span>
        </article>
        """
    else
        """
        <article
            class="field"
            ng-if="#{x}"
            ng-class="::{first:$index && #{x}.group != available[$index-1].group}">
                <span class="group">{{ ::#{x}.group }}</span>
                <span class="label">{{ ::#{x}.label }}</span>
        </article>
        """


module.directive 'reportParamsSortOrderSelect', (UISelectDirective) ->  UISelectDirective (x) ->
    """
    <article class="order">
        <span class="label">{{ #{x}.label }}</span>
    </article>
    """


module.directive 'reportParamsSort', ($q, Utils, ReportParamsSortModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-sort">
        <header>
            <h1>How should the report be sorted and limited?</h1>
        </header>
        <main ng-if="!model.selected">
            <p>{{ view.errorMessage }}</p>
        </main>
        <main ng-if="model.selected && view.mode == MODES.linked">
            <article class="sort-select">
                <div class="selectors">
                    <div class="selector field-select">
                        <report-params-sort-field-select available="model.selected[0].field.available" selected="model.selected[0].field.selected"></report-params-sort-field-select>
                    </div>
                    <div class="selector order-select">
                        <report-params-sort-order-select available="model.selected[0].order.available" selected="model.selected[0].order.selected"></report-params-sort-field-select>
                    </div>
                    <div class="selector limit">
                        <label>
                            <span>limit:</span>
                            <input type="number" step="1" min="0" ng-model="model.selected[0].limit" placeholder="—"></input>
                        </label>
                    </div>
                </div>
            </article>
            <button class="button-bare" ng-if="model.selected.length > 1" ng-click="setMode(MODES.separate)">
                Click here to apply a sort and limit to each property independently...
            </button>
        </main>
        <main ng-if="model.selected && view.mode == MODES.separate">
            <article class="sort-select" ng-repeat="item in model.selected track by item.property.id">
                <div class="property">
                    <span class="index">{{ $index + 1 }}.</span>
                    <span class="label">{{ item.property.label }}</span>
                </div>
                <div class="selectors">
                    <div class="selector field-select">
                        <report-params-sort-field-select available="item.field.available" selected="item.field.selected"></report-params-sort-field-select>
                    </div>
                    <div class="selector order-select">
                        <report-params-sort-order-select available="item.order.available" selected="item.order.selected"></report-params-sort-field-select>
                    </div>
                    <div class="selector limit">
                        <label>
                            <span>limit:</span>
                            <input type="number" step="1" min="0" ng-model="item.limit" placeholder="—"></input>
                        </label>
                    </div>
                </div>
            </article>
            <button class="button-bare" ng-if="model.selected.length > 1 && canChangeToLinkedMode()" ng-click="setMode(MODES.linked)">
                Click here to apply the same sort and limit to all properties...
            </button>
        </main>
    </article>
    """
    link: (scope) ->
        scope.MODES = {'linked', 'separate'}
        scope.view  = {mode: null}
        scope.model = null

        scope.$watch 'model.missing', ((missing) -> scope.view.errorMessage = do ->
            items = []
            items.push('how the report should be broken down') if missing?.properties
            items.push('which metrics should be included') if missing?.metrics
            return "" if items.length is 0
            return "You must first specify #{items.join(' and ')}."
        ), true

        updateModelFromParams = (params) ->
            scope.model.params = params or scope.params
            scope.model.updateModelFromParams()
            return $q.when()

        hasMultipleDistinctSortings = ->
            return null if not Array.isArray(scope.model?.selected)
            return true if _.uniq(scope.model.selected.map (x) -> x.order.selected.id).length > 1
            return true if _.uniq(scope.model.selected.map (x) -> if _.isNil(x.limit) then "null" else x.limit.toString()).length > 1
            return false

        scope.canChangeToLinkedMode = ->
            return null if not Array.isArray(scope.model?.selected)
            hasTimeProperties = scope.model.selected.some((x) -> isTimeProperty(x.property.id))
            hasNormProperties = scope.model.selected.some((x) -> not isTimeProperty(x.property.id))
            return not (hasTimeProperties and hasNormProperties)

        # If not all field/order/limit are the same, we switch to separate mode
        updateMode = ->
            scope.view.mode = do ->
                return null if not Array.isArray(scope.model?.selected)
                return scope.MODES.separate if not scope.canChangeToLinkedMode()
                return scope.MODES.linked if scope.model.selected.length is 1
                return scope.view.mode ? scope.MODES.linked

        updateSelected = ->
            if scope.view.mode is scope.MODES.linked and scope.model?.selected?.length > 1
                # We do a double check here, to make sure the mode is legit, because we're
                # going to mess with the user's selection
                if not scope.canChangeToLinkedMode()
                    console.warn("Trying to switch to linked mode, but can't. This is a bug.")
                    scope.setMode(scope.MODES.separate)
                    return
                copyFrom = scope.model.selected[0]
                scope.model.selected.slice(1).forEach (item) ->
                    item.field.selected = _.find item.field.available, (x) -> x.id is copyFrom.field.selected.id
                    item.order.selected = _.find item.order.available, (x) -> x.id is copyFrom.order.selected.id
                    item.limit = copyFrom.limit

            scope.model?.updateParamsFromModel()
            return

        scope.setMode = (mode) ->
            scope.view.mode = mode

        scope.getModelHash = ->
            return null if not scope.model?.selected
            return Utils.object.hash(scope.model.selected)

        initModel = do ->
            promise = null
            return (params) ->
                scope.model ?= new ReportParamsSortModel(params)
                promise ?= scope.model.init()
                return promise.then ->
                    scope.model.updateModelFromParams(params)
                    return scope.model

        watchers = []
        scope.$watch 'params', (params) ->
            watchers.forEach (x) -> x()
            watchers = []
            return if _.isNil(params)
            scope.setMode(null)
            initModel(params).then ->
                console.log(scope.model.selected[0].order.selected)
                scope.setMode do ->
                    return scope.MODES.separate if hasMultipleDistinctSortings()
                    return scope.MODES.linked

                watchers.push scope.$watch 'view.mode', ->
                    updateMode()
                    updateSelected()

                watchers.push scope.$watch 'model.params', ->
                    scope.setMode do ->
                        return scope.MODES.separate if hasMultipleDistinctSortings()
                        return scope.MODES.linked

                watchers.push scope.$watch 'getModelHash()', (hash) ->
                    updateMode()
                    updateSelected()

                watchers.push scope.$watch 'params.hierarchyStore', (properties) ->
                    return if _.isUndefined(scope.params) or _.isUndefined(properties)
                    updateModelFromParams()

                watchers.push scope.$watch 'params.metrics', (metrics) ->
                    return if _.isUndefined(scope.params) or _.isUndefined(metrics)
                    updateModelFromParams()



module.factory 'ReportParamsSortModel', (Utils, SchedulingHierarchy) ->

    class ReportParamsSortModel

        constructor: (@params) ->
            @selected = null
            @missing = null

        init: ->
            @refresh()

        refresh: ->
            SchedulingHierarchy.fetch().then (x) => @properties = x

        updateModelFromParams: (params = null) ->
            @params = params if params

            selected =
                metrics: (@params.metrics or []).map (x) ->
                    return {id:x.field, group:x.headerGroup, label:x.headerName or "TY", field:x.field}
                properties: do =>
                    propertiesById = _.keyBy @properties, (x) -> x.id
                    propertyIds = @params.hierarchyStore or []
                    return Utils.copy propertyIds.map((id) -> propertiesById[id]).filter((x)->x)

            @missing =
                properties: selected.properties.length is 0
                metrics: selected.metrics.length is 0

            if @missing.metrics or @missing.properties
                @selected = null
                return

            paramsPropertiesById = _.keyBy @params.sort, (x) -> x.property
            selectedProperties = selected.properties.map (property) =>
                existing = paramsPropertiesById[property.id]

                available = do ->
                    label = if isTimeProperty(property.id) then "Chronological" else "Alphanumeric"
                    return
                        fields: [{id:"property", group:"Property", label, field:property.id}].concat(Utils.copy selected.metrics)
                        # orders: [{id:1, label:"🞁 Asc", order:1}, {id:-1, label:"🞃 Desc", order:-1}]
                        orders: [{id:1, label:"Asc", order:1}, {id:-1, label:"Desc", order:-1}]

                availableFieldsById = _.keyBy available.fields, (x) -> x.field

                field = do ->
                    available: available.fields
                    selected: do ->
                        defaultValue = do ->
                            return available.fields[0] if isTimeProperty(property.id)
                            return available.fields[1]
                        return defaultValue if not existing
                        return availableFieldsById[existing.field] or defaultValue

                order = do ->
                    available: available.orders
                    selected: do ->
                        defaultValue = do ->
                            return available.orders[0] if isTimeProperty(property.id)
                            return available.orders[1]
                        return defaultValue if not existing
                        return _.find(available.orders, (x) -> x.order is existing.order) or defaultValue

                return
                    property: property
                    limit: existing?.limit or null
                    field: field
                    order: order

            @selected = selectedProperties



        updateParamsFromModel: ->
            @params.sort = do =>
                return null if not @selected
                return @selected.map ({property, field, order, limit}) ->
                    property: property.id
                    field:    field.selected.field
                    order:    order.selected.order
                    limit:    limit



module.factory 'UISelectDirective', ($window, OutsideElementClick) -> (templateFn) ->
    restrict: "E"
    scope:
        available: "="
        selected:  "="
    replace: true
    template: \
    """
    <article class="ui-select2" ng-class="{active:view.active}">
        <section class="faceplate" ng-click="view.toggle()">
            <div class="selected">#{templateFn('selected')}</div>
            <chevron-down-micro class="react-icon" model="'icon-14'" ></chevron-down-micro>
        </section>
        <section class="popup">
            <ul><li ng-repeat="item in view.available track by item.id" ng-click="view.select(item)">
            #{templateFn('item')}
            </li>
            </ul>
        </section>
    </article>
    """
    link: (scope, element) ->
        $faceplate = $(element).find('.faceplate')
        $popup     = $(element).find('.popup')

        scope.$on '$destroy', do ->
            unsub = preventOverscroll($popup)
            return unsub

        OutsideElementClick scope, element, ->
            return if not scope.view.active
            scope.view.active = false
            updateWidth()

        updateWidth = ->
            $faceplate.width do ->
                return 'auto' if not scope.view.active
                return $faceplate.width() if not $window.getComputedStyle
                style = $window.getComputedStyle($faceplate[0])
                styles = ['padding-left', 'padding-right', 'border-left-width', 'border-right-width']
                width = parseFloat(style.width.replace('px', ''))
                return styles.reduce ((sum, key) ->
                    value = style[key]
                    return sum if not value
                    value = parseFloat value.replace('px', '')
                    value = 0 if _.isNaN(value)
                    return sum - value
                ), width
            return


        scope.$watch 'available', (items) ->
            scope.view.available = (items or []).filter (x) -> x.id

        scope.view =
            active: false
            available: []
            toggle: ->
                scope.view.active = not scope.view.active
                updateWidth()
            select: (item) ->
                scope.selected = item
                scope.view.active = false
            isSelected: (item) ->
                scope.selected is item
