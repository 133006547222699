import { react2angular } from "react2angular";
import { HandRaisedIcon, HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/solid'
import { IIconProps, parseClassName } from './icons.misc'

const HandThumbUp = (props: IIconProps) => {
    const className = parseClassName(props);
    return <HandThumbUpIcon className={ className }/>;
}

const HandThumbDown = (props: IIconProps) => {
    const className = parseClassName(props);
    return <HandThumbDownIcon className={ className }/>;
}

const HandRaised = (props: IIconProps) => {
    const className = parseClassName(props);
    return <HandRaisedIcon className={ className }/>;
}

const HandsModule = angular
    .module('42.components.icons.handThumbs', [])
    .component('handThumbUp', react2angular(HandThumbUp, ['model']))
    .component('handThumbDown', react2angular(HandThumbDown, ['model']))
    .component('handRaised', react2angular(HandRaised, ['model']));

export const HANDS_ICONS = {
    'hand-thumb-up': HandThumbUp,
    'hand-thumb-down': HandThumbDown,
    'hand-raised': HandRaised,
}

export default HandsModule;
