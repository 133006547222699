import { react2angular } from "react2angular";
import { PencilIcon } from '@heroicons/react/24/solid'
import { IIconProps, parseClassName } from "./icons.misc";

const Pencil = (props: IIconProps) => {
    const className = parseClassName(props);
    return <PencilIcon className={className} />;
}

const PencilModule = angular
    .module('42.components.icons.pencil', [])
    .component('pencil', react2angular(Pencil, ['model']));

export const PENCIL_ICONS = {
    'pencil': Pencil,
}

export default PencilModule;
