import { react2angular } from "react2angular";
import { TvIcon } from '@heroicons/react/24/outline'
import { IIconProps, parseClassName } from './icons.misc'

const Tv = (props: IIconProps) => {
    const className = parseClassName(props);
    return <TvIcon className={className} />;
}

const TvModule = angular
    .module('42.components.icons.tv', [])
    .component('tv', react2angular(Tv, ['model']));

export const TV_ICONS = {
    'tv': Tv,
}

export default TvModule;
