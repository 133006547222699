import { react2angular } from "react2angular";
import { BookOpenIcon } from '@heroicons/react/24/outline'
import { IIconProps, parseClassName } from "./icons.misc";

const BookOpen = (props: IIconProps) => {
    const className = parseClassName(props);
    return  <div className="support-icon"><BookOpenIcon className={className} /></div>;
}

const BookOpenModule = angular
    .module('42.components.icons.bookOpen', [])
    .component('bookOpen', react2angular(BookOpen, ['model']));

export const BOOK_OPEN_ICONS = {
    'book-open': BookOpen,
}

export default BookOpenModule;
