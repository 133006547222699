import { react2angular } from "react2angular";
import { DocumentTextIcon as DocumentTextSolidIcon } from '@heroicons/react/24/solid'
import { DocumentTextIcon as DocumentTextOutlineIcon } from '@heroicons/react/24/outline'
import { IIconProps, parseClassName } from './icons.misc'

const DocumentTextSolid = (props: IIconProps) => {
    const className = parseClassName(props);
    return  <DocumentTextSolidIcon className={className} />;
}
const DocumentTextOutline = (props: IIconProps) => {
    const className = parseClassName(props);
    return  <DocumentTextOutlineIcon className={className} />;
}

const DocumentTextModule = angular
    .module('42.components.icons.document-text', [])
    .component('documentTextSolid', react2angular(DocumentTextSolid, ['model']))
    .component('documentTextOutline', react2angular(DocumentTextOutline, ['model']));

export const DOCUMENT_TEXT_ICONS = {
    'document-text-solid': DocumentTextSolid,
    'document-text-outline': DocumentTextOutline,
}

export default DocumentTextModule;
