import { react2angular } from "react2angular";
import { XCircleIcon } from '@heroicons/react/16/solid'
import { IIconProps, parseClassName } from './icons.misc'

export const XCircle = (props: IIconProps) => {
    const className = parseClassName(props);
    return <XCircleIcon className={className} />;
}

const XCircleModule = angular
    .module('42.components.icons.x-circle', [])
    .component('circleX', react2angular(XCircle, ['model']));

export const X_CIRCLE_ICONS = {
    'x-circle': XCircle,
}

export default XCircleModule;
