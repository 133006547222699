import { react2angular } from "react2angular";
import { EllipsisVerticalIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/solid'
import { IIconProps, parseClassName } from './icons.misc'

const EllipsisVertical = (props: IIconProps) => {
    const className = parseClassName(props);
    return <EllipsisVerticalIcon className={className}/>;
};

const EllipsisVerticalMicro = (props: IIconProps) => {
    const EllipsisVerticalMicroIcon = (props: {className: string}) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={props.className} viewBox="0 0 16 16" fill="currentColor">
                <path d="M8 2a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM8 6.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM9.5 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
            </svg>
        );
    }

    const className = parseClassName(props);
    return <EllipsisVerticalMicroIcon className={className}/>;
}


const EllipsisHorizontal = (props: IIconProps) => {
    const className = parseClassName(props);
    return <EllipsisHorizontalIcon className={ className }/>;
}

const EllipsisModule = angular
    .module('42.components.icons.ellipsis', [])
    .component('ellipsisVertical', react2angular(EllipsisVertical, ['model']))
    .component('ellipsisVerticalMicro', react2angular(EllipsisVerticalMicro, ['model']))
    .component('ellipsisHorizontal', react2angular(EllipsisHorizontal, ['model']));

export const ELLIPSIS_ICONS = {
    'ellipsis-vertical': EllipsisVertical,
    'ellipsis-vertical-micro': EllipsisVerticalMicro,
    'ellipsis-horizontal': EllipsisHorizontal,
}

export default EllipsisModule;
