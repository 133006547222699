import { react2angular } from "react2angular";
import { XCircle } from "./icons/x-circle.icon";
import { XMarkSmall } from "./icons/x-mark.icon";

interface ICloseButtonProps { onClose: () => void; }

const ButtonClose = (props: ICloseButtonProps) => {
    return (
        <div className="button-close" onClick={props.onClose}>
            <div className="react-icon cancel-icon">
                <XMarkSmall model="icon-12" ></XMarkSmall>
            </div>
            <div className="react-icon cancel-circled">
                <XCircle model="icon-14"></XCircle>
            </div>
        </div>
    );
}

const buttonCloseModule = angular
    .module('42.components.button-close-react', [])
    .component('buttonClose', react2angular(ButtonClose, ["onClose"]));

export default buttonCloseModule;
