import { react2angular } from "react2angular";
import { ShareIcon } from '@heroicons/react/20/solid'
import { IIconProps, parseClassName } from './icons.misc'

const Share = (props: IIconProps) => {
    const className = parseClassName(props);
    return <ShareIcon className={className} />;
}

const ShareModule = angular
    .module('42.components.icons.share', [])
    .component('share', react2angular(Share, ['model']));

export const SHARE_ICONS = {
    'share': Share,
}

export default ShareModule;
