import { react2angular } from "react2angular";
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { IIconProps, parseClassName } from './icons.misc'

const PaperAirplane = (props: IIconProps) => {
    const className = parseClassName(props);

    return <div className="support-icon" ><PaperAirplaneIcon className={ className }/></div>;
};

const PaperAirplaneModule = angular
    .module('42.components.icons.paperAirplane', [])
    .component('paperAirplane', react2angular(PaperAirplane, ['model']));

export const PAPER_AIRPLANE_ICONS = {
    'paper-airplane': PaperAirplane,
}

export default PaperAirplaneModule;
