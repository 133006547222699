import _ from 'lodash';
import 'jstree';

import 'angular-route';
import 'angular-sanitize';
import 'angular-promise-tracker';
import { isRequestAbortedError } from './lib/api/api';
import './filters';
import './components';
import './modules';
import './directives';
import './controllers';
import './router';
import { IHttpProvider, IRootScopeService, ISCEProvider } from 'angular';
import { IConfigObj } from './lib/types';
import { Routes } from './router';
import { DashboardRootScope } from './controllers/main-controller';
import { IConfigRoute } from './lib/config-routes';
import { isObject } from './lib/utils/utils';

const app = angular.module('42.dashboard', [
    'ui',
    'ngSanitize',
    'ngRoute',
    'infinite-scroll',
    '42.filters',
    '42.routes',
    '42.config',
    '42.modules',
    '42.controllers',
    '42.directives',
    '42.components',
    'ajoslin.promise-tracker',
    'ui.bootstrap',
]);

window.app = app;

// Angular UI configuration
app.value('ui.config', {});

// FIXME: Temporary until angular ui uses $sce for `highlight` filter.
app.config(function AppSCEProvider($sceProvider: ISCEProvider) {
    $sceProvider.enabled(false);
});

app.config(function AppHttpProvider($httpProvider: IHttpProvider & { defaults: { useXDomain: boolean } }) {
    // This is to prevent angular from removing keys prefixed with `$`,
    // as those are needed for the query api.
    $httpProvider.defaults.transformRequest = [(data: unknown) => JSON.stringify(data)];

    // Allows CORS
    $httpProvider.defaults.useXDomain = true;

    if ($httpProvider.defaults.headers && isObject($httpProvider.defaults.headers.common)) {
        delete $httpProvider.defaults.headers.common['X-Requested-With'];
    }
});

app.run(function AppInitializeRunner($rootScope: IRootScopeService & { initialized: boolean }) {
    $rootScope.initialized = false;
});

app.run([
    '$route',
    '$rootScope',
    '$location',
    function AppRouterRunner(
        $route: angular.route.IRouteService,
        $rootScope: angular.IRootScopeService,
        $location: angular.ILocationService,
    ) {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const original = $location.path;

        function newPath(): string;
        function newPath(path: string, reload?: boolean): angular.ILocationService;
        function newPath(path?: string, reload?: boolean): string | angular.ILocationService {
            if (typeof reload === 'boolean' && typeof reload === 'boolean' && !reload) {
                const lastRoute = $route.current;
                const unsub = $rootScope.$on('$locationChangeSuccess', () => {
                    $route.current = lastRoute;
                    unsub();
                });
            }
            // @ts-expect-error - TS doesn't like the fact that we're calling the original function with a different signature
            return original.apply($location, [path]);
        }

        $location.path = newPath;
    },
]);

app.config(function AppConfigBoot(
    $provide: angular.auto.IProvideService,
    $routeProvider: angular.route.IRouteProvider,
    $locationProvider: angular.ILocationProvider,
    ROUTES: Routes,
    CONFIG: IConfigObj,
) {
    $locationProvider.html5Mode(true);
    const redirectTo: string = (() => {
        return (
            CONFIG.routes.overview?.url ?? CONFIG.routes.sales?.url ?? ROUTES.overview?.url ?? ROUTES.sales?.url ?? '/'
        );
    })();
    $routeProvider.otherwise({ redirectTo });
    // Workaround for showing source maps on unhandled exceptions that happen in angular
    // https://github.com/angular/angular.js/issues/5217#issuecomment-50993513
    $provide.decorator('$exceptionHandler', function ExceptionHander() {
        return function ExceptionHandlerClosure(exception: Error, cause: string | undefined) {
            // $delegate(exception, cause)
            return setTimeout(() => {
                if (isRequestAbortedError(exception)) return;
                if (cause) console.error('Cause:', cause);
                throw exception;
            });
        };
    });
});

app.directive('route', function AppRouteDirective() {
    return {
        restrict: 'E',
        scope: {
            route: '=model',
            submenu: '@',
            showIcon: '@',
        },
        replace: true,
        template: `
            <li ng-class="{'active': route.active}">
                <a ng-href="{{ link }}" ng-class="{'active': route.active}" target="{{ route.href && '_blank' }}">
                    <span class="route-label">{{ label }}</span>
                </a>
            </li>
        `,
        link: function AppRouteDirectiveLink(
            scope: {
                label: string | undefined;
                link: string | undefined;
                route: IConfigRoute;
                submenu: string | undefined;
            } & angular.IScope,
        ) {
            scope.label = (scope.submenu && scope.route.subLabel) || scope.route.label;
            // Remove last path param
            scope.link = (scope.route.href ?? scope.route.url ?? '').split('/:')[0];
        },
    };
});

app.run(function AppRouteLocationRunner(
    $rootScope: DashboardRootScope,
    $location: angular.ILocationService,
    $route: angular.route.IRouteService,
    ROUTES: Routes,
    CONFIG: IConfigObj,
) {
    ROUTES = (() => {
        const routes = _.cloneDeep(ROUTES);
        Object.keys(routes).forEach(routeId => {
            const routeOverrides = _.pick(CONFIG.routes[routeId], 'label', 'url', 'icon');
            if (_.isEmpty(routeOverrides)) return;
            routes[routeId] = _.extend(routes[routeId], routeOverrides);
            const route = routes[routeId];
            if (!route) return;
            route.templates = [route.url];
        });
        return routes;
    })();

    $rootScope.routes = ROUTES;

    $rootScope.$on('$routeChangeSuccess', () => {
        $rootScope.activeRoute = null;
        for (const route of _.values($rootScope.routes)) {
            route.active =
                $route.current && 'originalPath' in $route.current ? $route.current.originalPath === route.url : false;
            if (route.active) $rootScope.activeRoute = route;
        }
    });

    let previousPagePath: string | null = null;
    $rootScope.$on('$viewContentLoaded', () => {
        const pagePath = $location.path();
        const pageName = (() => {
            if (
                $rootScope.activeRoute &&
                'label' in $rootScope.activeRoute &&
                typeof $rootScope.activeRoute.label === 'string'
            ) {
                return $rootScope.activeRoute.label;
            }
            return pagePath;
        })();
        if (previousPagePath === pagePath) return;
        previousPagePath = pagePath;
        analytics.page(pageName, {
            title: pageName,
            path: pagePath,
        });
    });
});
