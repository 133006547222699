import _ from 'lodash';
import type { AngularInjected } from '../../../lib/angular';
import type { IQuery } from '../../../lib/types';
import { Parse } from '../../../lib/parsers/values';
import type { IQueryServiceAPI } from '../../../modules/services/query-service.types';
import type { ISalesChartsSelectModel } from '../overview-views';
import type { IOverviewStat } from '../overview-card-charts';

interface CardOverallStatDirectiveScope extends angular.IScope {
    chart?: IOverviewStat;
    data?: Record<string, unknown>;
    selectChart: (chart: IOverviewStat) => void;
    view?: ICardOverallStatViewModel;
}

type ICardOverallStatViewModel = InstanceType<ReturnType<typeof CardOverallStatViewModelFactory>>;
const CardOverallStatViewModelFactory = ($filter: angular.IFilterFunction) => {
    const applyFilter = (type: null | undefined | string, value: unknown): null | string | number => {
        const num = Parse.Double(value);
        if (_.isNil(num)) return num;
        if (typeof type !== 'string') return num;
        const [filter, ...args] = type.split(':');
        const fn: unknown = $filter(filter);
        if (typeof fn !== 'function') return num;
        const result: unknown = fn(value, ...args);
        return typeof result === 'string' || typeof result === 'number' ? result : null;
    };

    return class CardOverallStatViewModel {
        readonly actual: {
            label: null | string;
            value: null | string | number;
        };
        readonly difference: null | {
            value: string | number;
            inverted: boolean;
            direction: null | 'positive' | 'negative';
        };
        readonly comparison: null | {
            label: null | string;
            value: string | number;
        };
        constructor(
            readonly chart: undefined | null | IOverviewStat,
            readonly data: undefined | null | Record<string, unknown>,
        ) {
            if (!chart || !data) {
                const label = chart?.title ?? null;
                this.actual = { label, value: null };
                this.difference = null;
                this.comparison = null;
            } else {
                const actual = chart.actual.field;

                this.actual = {
                    label: chart.actual.headerGroup,
                    value: applyFilter(chart.type, data[actual]),
                };

                this.comparison = (() => {
                    if (!chart.comparison) return null;
                    const label = chart.comparison.headerGroup;
                    const value = applyFilter(chart.type, data[chart.comparison.field]);
                    return _.isNil(value) ? null : { value, label };
                })();

                this.difference = (() => {
                    if (_.isNil(this.comparison)) return null;
                    if (!chart.percentage) return null;
                    const value = Parse.Double(data[chart.percentage.field]);
                    if (_.isNil(value)) return null;

                    // prettier-ignore
                    const direction =
                        value > 0 ? 'positive' :
                        value < 0 ? 'negative' :
                        null;

                    const inverted = chart.inverted ?? false;

                    return { value: value, direction, inverted };
                })();
            }
        }
    };
};

const CardOverallStatDirectiveFactory = () => [
    '$filter',
    function CardOverallStatsDirective(
        $filter: angular.IFilterFunction,
    ): angular.IDirective<CardOverallStatDirectiveScope> {
        const CardOverallStatViewModel = CardOverallStatViewModelFactory($filter);
        return {
            restrict: 'E',
            replace: true,
            scope: {
                chart: '=',
                data: '=',
            },
            template: `
            <div class="stats-content">
                <div class="actual-stat">
                    <div class="title">{{ view.actual.label || '' }}</div>
                    <div class="value" ng-class='{blank:view.actual.value === null}'>
                    {{ view.actual.value === null ? '–' : view.actual.value }}
                    </div>
                </div>
                <div class="growth">
                    <div class="growth-stat"
                        ng-hide="!view.comparison"
                        ng-class="{
                            'percent-invert-color': view.difference.inverted,
                            'percent-positive': view.difference.direction === 'positive',
                            'percent-negative': view.difference.direction === 'negative'
                        }">
                        <chevron-up-micro class="react-icon" ng-if="view.difference.direction === 'positive'" ></chevron-up-micro>
                        <chevron-down-micro class="react-icon" ng-if="view.difference.direction === 'negative'" ></chevron-down-micro>
                        <div class="growth-stat-description">
                            <span ng-show="view.difference">{{ view.difference.value | percent:true:false }}</span>
                            <span>(vs. {{ (view.comparison.label !== view.actual.label ? view.comparison.label : '') || '' }} {{ view.comparison.value }})</span>
                        </div>
                    </div>
                </div>
            </div>`,
            link: function CardOverallStatDirectiveLink(scope) {
                const updateView = () => {
                    scope.view = new CardOverallStatViewModel(scope.chart, scope.data);
                };
                scope.$watch('chart', updateView);
                scope.$watch('data', updateView);
                updateView();
            },
        };
    },
];

interface CardOverallStatsDirectiveScope extends angular.IScope {
    model: IOverviewStatsView;
    selectChart: (chart: IOverviewStat) => void;
}

const CardOverallStatsDirectiveFactory = () =>
    function CardOverallStatsDirective(): angular.IDirective<CardOverallStatsDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
            <div class="card stats overall-stats large initializing"
                ng-class="{loading: model.isLoading, error: model.isLoading === null}">

                <div class="message-container">
                    <div class="message">
                        <exclamation-triangle class="react-icon"></exclamation-triangle>
                        <span class="message-summary">Data could not be loaded</span>
                    </div>
                </div>

                <ul>
                    <li ng-repeat="chart in model.charts.view.available track by chart.id"
                        ng-click="model.selectChart(chart.model)"
                        ng-class="{selected: model.charts.view.selected.id == chart.id}"
                    >
                        <card-overall-stat chart="chart.model" data="model.data"></card-overall-stat>
                    </li>
                </ul>
            </div>
            `,
            link: function CardOverallStatsDirectiveLink(scope, $element) {
                const element = $element[0];
                if (!element) throw new Error('Card overall stats HTML element not found');

                scope.$watch('model.isLoading', (isLoading?: boolean) => {
                    element.classList.remove('initializing');
                    if (isLoading) element.classList.add('initializing');
                });

                const onResizeFn = (): void => {
                    const size = element.getBoundingClientRect();
                    const sizeClass = (() => {
                        if (size.width >= 920) return 'large';
                        if (size.width >= 600) return 'medium';
                        return 'small';
                    })();
                    const sizeChanged = !element.classList.contains(sizeClass);
                    if (!sizeChanged) return;
                    element.classList.remove('large', 'medium', 'small');
                    element.classList.add(sizeClass);
                };

                // Debounce here to avoid the error: ResizeObserver - loop limit exceeded
                // The error is harmless, but it's spamming sentry
                const onResize = _.debounce(onResizeFn, 1);
                onResize();

                const resizeObserver = new ResizeObserver(onResize);
                resizeObserver.observe(element);
                scope.$on('$destroy', () => resizeObserver.disconnect());
            },
        };
    };

export type IOverallStatsService = AngularInjected<typeof OverallStatsServiceFactory>;
export const OverallStatsServiceFactory = () => [
    'QueryServiceAPI',
    function OverallStatsService(QueryServiceAPI: IQueryServiceAPI) {
        const fetch = async (charts: IOverviewStat[], query: IQuery): Promise<null | Record<string, unknown>> => {
            query = _.cloneDeep(query);
            const api = await QueryServiceAPI();
            query.options = {};
            query.options.properties = ['stores.aggregate'];
            query.options.includeTotals = false;
            query.options.metrics = _.uniq(
                [
                    ...charts.map(chart => chart.actual.field),
                    ...charts.map(chart => chart.comparison?.field),
                    ...charts.map(chart => chart.percentage?.field),
                ].filter(Boolean),
            );
            const result = await api.query.metricsFunnel(query);
            const data = Array.isArray(result) ? result[0] : result;
            return data ?? null;
        };
        return { fetch };
    },
];

export interface IOverviewStatsViewOptions {
    data?: null | Record<string, unknown>;
    query: IQuery;
    charts: ISalesChartsSelectModel;
    selectChart: (chart: IOverviewStat) => void;
}
export type IOverviewStatsViewFactory = AngularInjected<typeof OverviewStatsViewFactory>;
export type IOverviewStatsView = InstanceType<IOverviewStatsViewFactory>;

export const OverviewStatsViewFactory = () => [
    '$q',
    'OverallStats',
    function OverviewStatsView($q: angular.IQService, OverallStats: IOverallStatsService) {
        return class OverviewStatsView {
            readonly charts: ISalesChartsSelectModel;
            readonly selectChart: (chart: IOverviewStat) => void;
            public data: null | Record<string, unknown>;
            public isLoading: null | boolean;

            constructor(options: IOverviewStatsViewOptions) {
                const { query, data, charts } = options;
                this.charts = charts;
                this.selectChart = options.selectChart;
                if (data) {
                    this.data = data;
                    this.isLoading = false;
                } else {
                    this.data = null;
                    this.isLoading = true;
                    void this.init(charts, query);
                }
            }

            protected init(charts: ISalesChartsSelectModel, query: IQuery) {
                this.isLoading = true;
                const promise = OverallStats.fetch(charts.getState().available, query);
                return $q
                    .when(promise)
                    .then(data => {
                        this.isLoading = false;
                        this.data = data;
                    })
                    .catch(error => {
                        this.isLoading = null;
                        this.data = null;
                        throw error;
                    });
            }
        };
    },
];

export default angular
    .module('42.controllers.overview.card-overall-stats', ['42.filters'])
    .directive('cardOverallStats', CardOverallStatsDirectiveFactory())
    .directive('cardOverallStat', CardOverallStatDirectiveFactory())
    .service('OverallStats', OverallStatsServiceFactory())
    .service('OverviewStatsView', OverviewStatsViewFactory());
