import { react2angular } from "react2angular";
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { IIconProps, parseClassName } from './icons.misc'

const MagnifyingGlass = (props: IIconProps) => {
    const className = parseClassName(props);
    return <MagnifyingGlassIcon className={className} />;
}

const MagnifyingGlassMicro = (props: IIconProps) => {
    const MagnifyingGlassMicroIcon = (props: {className: string}) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={props.className} viewBox="0 0 16 16" fill="currentColor">
                <path fillRule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clipRule="evenodd" />
            </svg>
        );
    }

    const className = parseClassName(props);
    return <MagnifyingGlassMicroIcon className={className}/>;
}

const MagnifyingGlassModule = angular
    .module('42.components.icons.magnifying-glass', [])
    .component('magnifyingGlass', react2angular(MagnifyingGlass, ['model']))
    .component('magnifyingGlassMicro', react2angular(MagnifyingGlassMicro, ['model']));

export const MAGNIFYING_GLASS_ICONS = {
    'magnifying-glass': MagnifyingGlass,
    'magnifying-glass-micro': MagnifyingGlassMicro,
}

export default MagnifyingGlassModule;
