import buttonExportModule from './export-button';
import multiCheckDropdownModule from './multi-check-dropdown';
import TooltipModule from './tooltip';
import MetricSelectorTreeModule from './metrics-selector-tree';
import buttonCloseModule from './close-button';
import listCustomHeaderModule from './list-custom-header';
import SidebarModule from './sidebar';
import actionsPanelModule from './actions-panel';
import PropertiesItemsModule from './properties-items';
import DragAndDropZoneModule from './drag-and-drop';
import StickyDirectiveModule from './sticky';
import bucketPickerModule from './bucket-picker';
import ComponentsIcons from './icons';

angular.module('42.components', [
    buttonExportModule.name,
    buttonCloseModule.name,
    multiCheckDropdownModule.name,
    TooltipModule.name,
    bucketPickerModule.name,
    MetricSelectorTreeModule.name,
    SidebarModule.name,
    actionsPanelModule.name,
    PropertiesItemsModule.name,
    DragAndDropZoneModule.name,
    StickyDirectiveModule.name,
    listCustomHeaderModule.name,
    ComponentsIcons.name,
]);
