import { react2angular } from "react2angular";
import { PlusIcon, PlusCircleIcon } from '@heroicons/react/16/solid';
import { IIconProps, parseClassName } from './icons.misc'

const Plus = (props: IIconProps) => {
    const className = (() => {
        if (typeof props.model === 'string') return props.model;
        return typeof props.model?.className === 'string' ? props.model.className : '';
    })();
    return <PlusIcon className={ className } />;
}

const PlusMicro = (props: IIconProps) => {
    const PlusMicroIcon = (props: {className: string}) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className={props.className} fill="currentColor">
                <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm.75-10.25v2.5h2.5a.75.75 0 0 1 0 1.5h-2.5v2.5a.75.75 0 0 1-1.5 0v-2.5h-2.5a.75.75 0 0 1 0-1.5h2.5v-2.5a.75.75 0 0 1 1.5 0Z" clipRule="evenodd" />
            </svg>
        );
    }

    const className = parseClassName(props);
    return <PlusMicroIcon className={className}/>;

}

const PlusCircle = (props: IIconProps) => {
    const className = (() => {
        if (typeof props.model === 'string') return props.model;
        return typeof props.model?.className === 'string' ? props.model.className : '';
    })();
    return <PlusCircleIcon className={ className } />;
}

const PlusCircleModule = angular
    .module('42.components.icons.plus-circle', [])
    .component('plus', react2angular(Plus, ['model']))
    .component('plusMicro', react2angular(PlusMicro, ['model']))
    .component('plusCircle', react2angular(PlusCircle, ['model']));

export const PLUS_ICONS = {
    'plus': Plus,
    'plus-micro': PlusMicro,
    'plus-circle': PlusCircle,
}

export default PlusCircleModule;
