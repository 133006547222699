import './selection-pebble.scss';

export type SelectionPebbleModel =
    | ({
          id: string;
          icon?: string;
          interactive?: boolean;
          onDoubleClick?: ($event: Event) => void;
          onClick?: ($event: Event) => void;
          onIconClick?: ($event: Event) => void;
          color: 'red' | 'blue' | 'neutral';
          draggable?: boolean;
          selectable?: boolean;
          chevron?: boolean;
      } & { label: string; value?: string | number })
    | { label?: string; value?: string | number };

interface SelectionPebbleDirectiveScope extends angular.IScope {
    model: SelectionPebbleModel;
    disabled: boolean | undefined;
}

export const SelectionPebbleDirective = () => [
    (): angular.IDirective<SelectionPebbleDirectiveScope> => ({
        restrict: 'E',
        scope: {
            options: '=',
            model: '=',
            disabled: '=',
        },
        replace: true,
        template: `
        <div
            class="selection-pebble pebble-color-{{ model.color || 'neutral' }}"
            ng-class="{
                'interactive': ((model.onIconClick || model.onClick || model.onDoubleClick) && !disabled),
                'draggable': model.draggable,
                'selectable': model.selectable,
                'multi-select': options.multiSelect,
                'selected': model.selected,
                'neutral': model.color === 'neutral',
                'translucid': model.translucid,
            }"
            ng-click="model.onClick($event)"
            ng-double-click="model.onDoubleClick($event)">
            <div class="pebble-content">
                <span ng-if="model.label !== undefined" class="pebble-label">{{ model.label }}</span>
                <span ng-if="model.value !== undefined" class="pebble-value">{{ model.value }}</span>
            </div>
            <div class="pebble-icon" ng-if="model.number">
                {{ model.number }}
            </div>
            <div class="selection-pebble-chevron" ng-if="model.chevron">
                <chevron-right-micro class="react-icon move-icon move-icon-right" model="'light-grey'" ></chevron-right-micro>
            </div>
            <generic-icon
                class="react-icon pebble-icon"
                ng-if="model.icon"
                icon="model.icon"
                model="'icon-14'"
                ng-click="model.onIconClick($event)">
            </generic-icon>
        </div>
        `,
    }),
];

const SelectionPebbleModule = angular
    .module('42.components.selection-pebble', [])
    .directive('selectionPebble', SelectionPebbleDirective());

export default SelectionPebbleModule;
