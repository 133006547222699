import { react2angular } from "react2angular";
import { ChevronDownIcon, ChevronUpIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { IIconProps, parseClassName } from "./icons.misc";

const ChevronDown = (props: IIconProps) => {
    const className = parseClassName(props);
    return <ChevronDownIcon className={className} />;
}
const ChevronDownMicro = (props: IIconProps) => {
    const ChevronDownMicroIcon = (props: {className: string}) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={props.className} viewBox="0 0 16 16" fill="currentColor">
                <path fillRule="evenodd" d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
            </svg>
        );
    }

    const className = parseClassName(props);
    return <ChevronDownMicroIcon className={className}/>;
}

const ChevronUp = (props: IIconProps) => {
    const className = parseClassName(props);
    return <ChevronUpIcon className={className} />;
}
const ChevronUpMicro = (props: IIconProps) => {
    const ChevronUpMicroIcon = (props: {className: string}) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={props.className} viewBox="0 0 16 16" fill="currentColor">
                <path fillRule="evenodd" d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z" clipRule="evenodd" />
            </svg>
        );
    }

    const className = parseClassName(props);
    return <ChevronUpMicroIcon className={className}/>;

}

const ChevronLeft = (props: IIconProps) => {
    const className = parseClassName(props);
    return <ChevronLeftIcon className={className} />;
}
const ChevronLeftMicro = (props: IIconProps) => {
    const ChevronLeftMicroIcon = (props: {className: string}) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={props.className} viewBox="0 0 16 16" fill="currentColor">
                <path fillRule="evenodd" d="M9.78 4.22a.75.75 0 0 1 0 1.06L7.06 8l2.72 2.72a.75.75 0 1 1-1.06 1.06L5.47 8.53a.75.75 0 0 1 0-1.06l3.25-3.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
            </svg>
        );
    }

    const className = parseClassName(props);
    return <ChevronLeftMicroIcon className={className}/>;

}

const ChevronRight = (props: IIconProps) => {
    const className = parseClassName(props);
    return <ChevronRightIcon className={className} />;
}

const ChevronRightMicro = (props: IIconProps) => {
    const ChevronRightMicroIcon = (props: {className: string}) => {
        return (
            <svg width="16" height="16" className={props.className} viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.21967 4.22431C6.51256 3.93142 6.98744 3.93142 7.28033 4.22431L10.5303 7.47431C10.8232 7.7672 10.8232 8.24208 10.5303 8.53497L7.28033 11.785C6.98744 12.0779 6.51256 12.0779 6.21967 11.785C5.92678 11.4921 5.92678 11.0172 6.21967 10.7243L8.93934 8.00464L6.21967 5.28497C5.92678 4.99208 5.92678 4.5172 6.21967 4.22431Z"/>
            </svg>
        );
    }

    const className = parseClassName(props);
    return <ChevronRightMicroIcon className={className}/>;

}

const ChevronModule = angular
    .module('42.components.icons.chevron', [])
    .component('chevronDown', react2angular(ChevronDown, ['model']))
    .component('chevronUp', react2angular(ChevronUp, ['model']))
    .component('chevronLeft', react2angular(ChevronLeft, ['model']))
    .component('chevronRight', react2angular(ChevronRight, ['model']))
    .component('chevronDownMicro', react2angular(ChevronDownMicro, ['model']))
    .component('chevronLeftMicro', react2angular(ChevronLeftMicro, ['model']))
    .component('chevronRightMicro', react2angular(ChevronRightMicro, ['model']))
    .component('chevronUpMicro', react2angular(ChevronUpMicro, ['model']));

export const CHEVRON_ICONS = {
    'chevron-down': ChevronDown,
    'chevron-up': ChevronUp,
    'chevron-left': ChevronLeft,
    'chevron-right': ChevronRight,
    'chevron-down-micro': ChevronDownMicro,
    'chevron-left-micro': ChevronLeftMicro,
    'chevron-right-micro': ChevronRightMicro,
    'chevron-up-micro': ChevronUpMicro,
}

export default ChevronModule;
