import { INoRowsOverlayComp } from '@ag-grid-community/all-modules';

const errorHTML = `
    <div class="error-message-container">
        <div class="message">
            <div class="react-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                </svg>
            </div>
            <span class="message-summary">Data could not be loaded</span>
        </div>
    </div>
`;

const noRowsHTML = `
    <div class="no-rows-message-container">
        <div class="message">
            <span class="message-summary">No Rows to display</span>
        </div>
    </div>
`;

export const GridOverlayMessageRendererFactory = () => {
    let isError = false;

    class GridOverlayMessageRenderer implements INoRowsOverlayComp {
        eGui: HTMLDivElement = document.createElement('div');

        init() {
            this.eGui = document.createElement('div');
            this.eGui.innerHTML = isError ? errorHTML : noRowsHTML;
        }

        getGui() {
            return this.eGui;
        }

        refresh() {
            return false;
        }
    }

    return {
        component: GridOverlayMessageRenderer,
        setError: (error: boolean) => {
            isError = error;
        },
    };
};
