import { react2angular } from "react2angular";
import { LightBulbIcon } from '@heroicons/react/24/solid'
import { IIconProps, parseClassName } from './icons.misc'

const LightBulb = (props: IIconProps) => {
    const className = parseClassName(props);
    return <LightBulbIcon className={ className }/>;
};

const LightBulbModule = angular
    .module('42.components.icons.light-bulb', [])
    .component('lightBulb', react2angular(LightBulb, ['model']));

export const LIGHT_BULB_ICONS = {
    'light-bulb': LightBulb,
}

export default LightBulbModule;
