import _ from 'lodash';
import * as ConfigFlags from '../lib/config-flags';
import { ConfigAPI } from '../lib/config-api';
import { CachedService } from '../lib/model/model-service-cached';
import { ISelectModelView, SelectModel } from '../lib/model/model-select';
import { react2angular } from 'react2angular';
import React, { Component } from 'react';

export interface IBucket {
    id: string;
    label: string;
}

interface BucketPickerProps {
    selected: undefined | IBucket;
    select: (bucket: IBucket) => void;
}

const resolveBucketId = (config: unknown): null | string => {
    const value: unknown = _.get(config, 'defaults.overviewBucket');
    return parseBucketId(value);
};

const parseBucketId = (value: unknown): null | 'day' | 'week' | 'month' => {
    value = typeof value === 'string' ? value.toLowerCase() : null;
    if (value === 'day' || value === 'timestamp') return 'day';
    if (value === 'week') return 'week';
    if (value === 'month') return 'month';
    return null;
};

const fetchDefaultBucketId = async () => {
    const config = await ConfigAPI.get();
    return Promise.all([
        config.organization.get(),
        config.user.getInternal(),
        //
    ]).then(([orgConfig, userConfig]) => {
        return resolveBucketId(userConfig) ?? resolveBucketId(orgConfig) ?? 'week';
    });
};

const fetchAvailableBuckets = async () => {
    const flags = await ConfigFlags.fetch();
    const available = [
        { id: 'timestamp', label: 'Day' },
        { id: 'week', label: 'Week' },
    ];
    if (flags.showMonthBucket) {
        available.push({ id: 'month', label: 'Month' });
    }
    return available;
};

const BucketPickerService = CachedService(async function fetch() {
    return Promise.all([fetchAvailableBuckets(), fetchDefaultBucketId()]).then(([available, selected]) => {
        return new SelectModel({ available, selected });
    });
});

// TODO: maybe a limitation of react2angular, but if I use the component above, it will run 3 time if the view is updated.
// The one bellow, just once
class BucketPickerComponent extends Component<BucketPickerProps> {
    view: ISelectModelView<IBucket> | undefined;
    model: SelectModel<IBucket, (x: IBucket) => string, string> | undefined;

    constructor(props: BucketPickerProps) {
        super(props);

        void BucketPickerService.fetch().then(model => {
            this.model = model;
            this.select(model.getSelected());
        });
    }

    select = (bucket: IBucket | { id: string; model: IBucket; }) => {
        if (!this.model) return;
        this.model.select(bucket.id);
        this.view = _.cloneDeep(this.model.view);
        this.props.select(this.model.getSelected());
    }

    override render() {
        return (
            <article className="bucket-picker">
                <ul>
                    {this.view?.available.map(bucket => (
                        <li
                            key={bucket.id}
                            onClick={() => this.select(bucket)}
                            className={this.view?.selected.id && this.view.selected.id === bucket.id ? 'active' : ''}
                        >
                            {bucket.model.label}
                        </li>
                    ))}
                </ul>
            </article>
        );
    }
}

const bucketPickerModule = angular
    .module('42.components.bucket-picker', [])
    .component('bucketPicker', react2angular(BucketPickerComponent, ['selected', 'select']));

export default bucketPickerModule;
