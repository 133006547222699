import './actions-panel.scss';
import { MetricsSelectorModel } from './metrics-selector-tree';

export type ToggleText = {
    label: string;
    id: string | number;
};

export type IToggleTextModel = [ToggleText, ToggleText];

interface ToggleTextDirectiveScope extends angular.IScope {
    available: IToggleTextModel;
    selected: string | number;
    onClick: (id: number | string) => void;
    clickItem: ($event: MouseEvent, textItem: ToggleText) => void;
}
export const ToggleTextDirective = () => [
    function ToggleTextDirective(): angular.IDirective<ToggleTextDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                onClick: '=',
                available: '=',
                selected: '=',
            },
            replace: true,
            template: `
                <div class="toggle-text">
                    <div class="toggle-left-text toggle-label"
                        ng-class="{selected: available[0].id === selected}"
                        ng-click="clickItem($event, available[0])">
                        {{ available[0].label }}
                    </div>
                    <div class="toggle-right-text toggle-label"
                        ng-class="{selected: available[1].id === selected}"
                        ng-click="clickItem($event, available[1])">
                        {{ available[1].label }}
                    </div>
                </div>
            `,
            link: function ToggleTextLink(scope) {
                scope.clickItem = ($event, textItem) => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();

                    if (textItem.id === scope.selected) return;
                    scope.selected = textItem.id;
                    scope.onClick(textItem.id);
                };
            },
        };
    },
];

export const ACTIONS_PANEL_MAIN_ITEM_TYPES = {
    simple: 'simple',
    button: 'button',
    select: 'select',
} as const;

export const ACTIONS_PANEL_SECONDARY_ITEM_TYPES = {
    simple: 'simple',
} as const;

interface ActionsPanelGenericItemArguments {
    id?: string | undefined;
    secondary?: boolean;
    cssClass?: string;
    label: string;
}
export class ActionsPanelGenericItem {
    readonly id: undefined | string;
    readonly secondary: boolean;
    public label: string;
    public cssClass: string;

    constructor({ id, secondary, cssClass, label }: ActionsPanelGenericItemArguments) {
        this.id = id;
        this.secondary = secondary ?? false;
        this.cssClass = cssClass ?? '';
        this.label = label;
    }
}

export interface ActionsPanelSimpleItemArguments extends ActionsPanelGenericItemArguments {
    icon?: string | { type: string };
    isLoading?: () => boolean;
    isActive?: () => boolean;
    onClick: (event: Event) => void;
}

export class ActionsPanelSimpleItem extends ActionsPanelGenericItem {
    readonly type = ACTIONS_PANEL_MAIN_ITEM_TYPES.simple;
    readonly isLoading: (() => boolean) | undefined;
    readonly isActive: (() => boolean) | undefined;
    readonly icon: { type: string } | undefined;
    readonly onClick: (event: Event) => void;
    constructor(options: ActionsPanelSimpleItemArguments) {
        super(options);
        this.isLoading = options.isLoading;
        this.isActive = options.isActive;
        this.icon = typeof options.icon === 'string' ? { type: options.icon } : options.icon;
        this.onClick = options.onClick;
    }
}

export interface ActionsPanelButtonItemArguments extends ActionsPanelGenericItemArguments {
    selected: string;
    secondary?: boolean;
    icon?: string | { type: string };
    svg?: string;
    isLoading?: () => boolean;
    isActive?: () => boolean;
    onClick: (event: Event) => void;
}

export class ActionsPanelButtonItem extends ActionsPanelGenericItem {
    readonly type = ACTIONS_PANEL_MAIN_ITEM_TYPES.button;
    readonly icon: { type: string; model?: string } | undefined;
    readonly svg: string | undefined;
    readonly selected: string;
    readonly isLoading: (() => boolean) | undefined;
    readonly isActive: (() => boolean) | undefined;
    readonly onClick: (event: Event) => void;
    constructor(options: ActionsPanelButtonItemArguments) {
        super(options);
        this.isLoading = options.isLoading;
        this.isActive = options.isActive;
        this.icon = typeof options.icon === 'string' ? { type: options.icon } : options.icon;
        this.svg = options.svg;
        this.selected = options.selected;
        this.onClick = options.onClick;
    }
}

export interface ActionsPanelSelectItemArguments<T> extends ActionsPanelGenericItemArguments {
    available: T[];
    selected: T;
    isActive?: () => boolean;
    onClick: (item: T) => void;
}

export class ActionsPanelSelectItem<T> extends ActionsPanelGenericItem {
    readonly type = ACTIONS_PANEL_MAIN_ITEM_TYPES.select;
    readonly available: T[];
    readonly selected: T;
    readonly isActive: (() => boolean) | undefined;
    readonly onClick: (item: T) => void;
    constructor(options: ActionsPanelSelectItemArguments<T>) {
        super(options);
        this.isActive = options.isActive;
        this.available = options.available;
        this.selected = options.selected;
        this.onClick = options.onClick;
    }
}

export interface ActionsPanelSecondaryItemArguments extends ActionsPanelGenericItemArguments {
    icon?: string | { type: string };
    onClick: (event: Event) => void;
}
export class ActionsPanelSecondaryItem extends ActionsPanelGenericItem {
    override secondary = true;
    icon: { type: string; model?: string } | undefined;
    onClick: (event: Event) => void;

    constructor(options: ActionsPanelSecondaryItemArguments) {
        super(options);
        this.icon = typeof options.icon === 'string' ? { type: options.icon } : options.icon;
        this.onClick = options.onClick;
    }
}

export class ActionsCustomMetricTreeModel extends MetricsSelectorModel {
    type = 'custom-metrics-tree';
}

export type ActionsPanelItem = ActionsPanelGenericItem;

export class ActionsPanelModel {
    items: ActionsPanelItem[] = [];

    constructor({ items }: { items: ActionsPanelItem[] } = { items: [] }) {
        this.items = items;
    }
}

interface ActionsPanelDirectiveScope extends angular.IScope {
    model?: ActionsPanelModel;
    mainActions: ActionsPanelItem[];
    secondaryActions: ActionsPanelItem[];
}

export const ActionsPanelSimpleItemDirective = () => [
    function ActionsPanelSimpleItemDirective(): angular.IDirective {
        return {
            restrict: 'E',
            scope: {
                item: '=',
            },
            replace: true,
            template: `
                <div class="select-action-item simple-item {{ item.cssClass || ''}}"
                    ng-class="{'active': item.isActive()}"
                    ng-click="item.onClick($event)">
                    <div class="loadable" ng-class="{loading: item.isLoading()}"></div>
                    <div class="button-action-item-selected reversed">
                        <generic-icon ng-if="item.icon" class="react-icon" icon="item.icon.type" model="item.icon.model"></generic-icon>
                        <div class="button-action-item-label">{{ item.label }}</div>
                    </div>
                </div>
            `,
        };
    },
];
export const ActionsPanelSimpleButtonItemDirective = () => [
    function ActionsPanelSimpleButtonItemDirective(): angular.IDirective {
        return {
            restrict: 'E',
            scope: {
                item: '=',
            },
            replace: true,
            template: `
                <div class="select-action-item {{ item.cssClass || ''}}"
                    ng-class="{'active': item.isActive()}"
                    ng-click="item.onClick($event)">
                    <div class="button-action-item-label">{{ item.label }}</div>
                    <div class="button-action-item-selected" ng-click="item.onClick($event)">
                        <div class="button-action-item-selected-label">
                            {{ item.selected }}
                        </div>
                        <generic-icon ng-if="item.icon" class="react-icon" icon="item.icon.type" model="item.icon.model"></generic-icon>
                    </div>
                </div>
            `,
        };
    },
];
export const ActionsPanelSelectItemDirective = () => [
    function ActionsPanelSelectItemDirective(): angular.IDirective<angular.IScope & { hovered: boolean }> {
        return {
            restrict: 'E',
            scope: {
                item: '=',
                id: '=',
            },
            replace: true,
            template: `
                <div class="select-action-item {{ item.cssClass || ''}}"
                    ng-class="{'active': item.isActive()}">
                    <div class="select-action-item-header">
                        <div class="button-action-item-label">{{ item.label }}</div>
                        <toggle-text
                            ng-if="item.sort"
                            available="item.sort.available"
                            selected="item.sort.selected"
                            on-click="item.sort.onClick">
                        </toggle-text>
                    </div>
                    <div class="button-action-item-selected select" ng-class="{'hovered': hovered }">
                        <div class="button-action-item-selected-label">
                            {{ item.selected.label }}
                        </div>
                        <generic-icon ng-if="item.icon" class="react-icon" icon="item.icon.type" model="item.icon.model"></generic-icon>
                    </div>
                    <select
                        id="id"
                        ng-options="option as (option.label) group by option.group for option in item.available"
                        ng-model="item.selected"
                        ng-change="item.onClick(item.selected)">
                    </select>
                </div>
            `,
            link: function SelectItemLink($scope, $element) {
                $scope.hovered = false;
                $element.find('select').on('mouseover', event => {
                    event.stopImmediatePropagation();
                    event.preventDefault();
                    $scope.hovered = true;
                    $scope.$applyAsync();
                });
                $element.find('select').on('mouseout', event => {
                    event.stopImmediatePropagation();
                    event.preventDefault();
                    $scope.hovered = false;
                    $scope.$applyAsync();
                });

                $scope.$on('$destroy', () => {
                    $element.find('select').off('mouseover');
                    $element.find('select').off('mouseout');
                });
            },
        };
    },
];
export const ActionsPanelSecondarySimpleItemDirective = () => [
    function ActionsPanelSecondarySimpleItemDirective(): angular.IDirective {
        return {
            restrict: 'E',
            scope: {
                item: '=',
            },
            replace: true,
            template: `
                <div class="secondary-simple-action-item {{ item.cssClass || ''}}"
                    ng-click="item.onClick($event)">
                    <div class="simple-action-item-label">{{ item.label }}</div>
                    <generic-icon ng-if="item.icon" class="react-icon" icon="item.icon.type" model="item.icon.model"></generic-icon>
                </div>
            `,
        };
    },
];
export const ActionsPanelSecondaryButtonItemDirective = () => [
    function ActionsPanelSecondaryButtonItemDirective(): angular.IDirective {
        return {
            restrict: 'E',
            scope: {
                item: '=',
            },
            replace: true,
            template: `
                <div class="secondary-button-action-item {{ item.cssClass || ''}}"
                    ng-class="{'active': item.isActive()}"
                    ng-click="item.onClick($event)">
                    <div class="loadable" ng-class="{loading: item.isLoading()}"></div>
                    <div class="simple-action-item-label">{{ item.label }}</div>
                    <generic-icon ng-if="item.icon" class="react-icon" icon="item.icon.type" model="item.icon.model"></generic-icon>
                </div>
            `,
        };
    },
];

export const ActionsPanelDirective = () => [
    function ActionsPanelDirective(): angular.IDirective<ActionsPanelDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <div class="actions-panel">
                    <div class="main-actions-panel">
                        <div class="actions-panel-item" ng-repeat="item in mainActions">
                            <actions-panel-simple-item        ng-if="item.type === 'simple'"             item="item"></actions-panel-simple-item>
                            <actions-panel-simple-button-item ng-if="item.type === 'button'"             item="item"></actions-panel-simple-button-item>
                            <actions-panel-select-item        ng-if="item.type === 'select'"             item="item" id="$index"></actions-panel-select-item>
                            <ui-metric-selector-tree          ng-if="item.type === 'custom-metrics-tree'" model="item"></ui-metric-selector-tree>
                        </div>
                    </div>
                    <div class="secondary-actions-panel">
                        <div class="actions-panel-item" ng-repeat="item in secondaryActions">
                            <actions-panel-secondary-simple-item ng-if="item.type === 'simple'" item="item" ></actions-panel-secondary-simple-item>
                            <actions-panel-secondary-button-item ng-if="item.type === 'button'" item="item" ></actions-panel-secondary-button-item>
                        </div>
                    </div>
                </div>
            `,
            link: function ActionsPanelLink($scope) {
                $scope.mainActions = [];
                $scope.secondaryActions = [];

                $scope.$watch('model', () => {
                    if (!$scope.model || $scope.model.items.length === 0) return;

                    $scope.mainActions = [];
                    $scope.secondaryActions = [];

                    for (const item of $scope.model.items) {
                        item.secondary ? $scope.secondaryActions.push(item) : $scope.mainActions.push(item);
                    }
                });
            },
        };
    },
];

const actionsPanelModule = angular
    .module('42.components.actions-panel', [])
    .directive('toggleText', ToggleTextDirective())
    .directive('actionsPanel', ActionsPanelDirective())
    .directive('actionsPanelSimpleItem', ActionsPanelSimpleItemDirective())
    .directive('actionsPanelSimpleButtonItem', ActionsPanelSimpleButtonItemDirective())
    .directive('actionsPanelSelectItem', ActionsPanelSelectItemDirective())
    .directive('actionsPanelSecondarySimpleItem', ActionsPanelSecondarySimpleItemDirective())
    .directive('actionsPanelSecondaryButtonItem', ActionsPanelSecondaryButtonItemDirective());

export default actionsPanelModule;
