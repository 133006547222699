import _ from 'lodash';
import {
    IMetricsFunnelNodeGridViewModel,
    IMetricsFunnelNodeGridViewModelFactory,
    IMetricsFunnelRowData,
} from '../../metrics/metrics-grid.directive';
import { IColumnDef } from '../../metrics/metrics-funnel';
import { ICardHeaderModel } from './ads-card-header.directive';
import { IAdView } from '../ads.controller';
import { IMetricDefinitionExtended } from '../ads.helper';
import { MetricsFunnelNode } from '../../metrics/metrics-funnel';
import { AdsPlatformConfig, IAdsService } from '../ads.service';
import { IMetricDefinition, IQuery } from '../../../lib/types';
import { IPropertyDefinition } from '../../../lib/config-hierarchy';
import { AngularInjected } from '../../../lib/angular';

export interface IAdGridOptions {
    node: MetricsFunnelNode;
    platform: string;
    title: string;
    metrics: IMetricDefinitionExtended[];
    availableMetrics: IMetricDefinition[];
    query: IQuery;
    config: AdsPlatformConfig;
    property: IPropertyDefinition;
}

export const AdsGridFactory = () => [
    'AdsService',
    function AdsGridFn(AdsService: IAdsService) {
        return class AdGrid {
            node: MetricsFunnelNode;
            platform: string;
            metrics: IMetricDefinitionExtended[];
            data: IMetricsFunnelRowData | null;
            title: string;
            isLoading: boolean | null = null;
            isError = false;

            constructor(options: IAdGridOptions) {
                const { platform, title, metrics, node } = options;
                this.metrics = metrics;
                this.platform = platform;
                this.title = title;
                this.node = node;
                this.data = null;

                void this.init(options);
            }

            async init({ availableMetrics, config, property, query }: IAdGridOptions) {
                this.isLoading = true;
                return AdsService.fetchGrid(availableMetrics, config, property, query)
                    .then(data => {
                        this.data = data;
                        this.isLoading = false;
                    })
                    .catch(err => {
                        console.warn(err);
                        this.isLoading = false;
                        this.isError = true;
                    });
            }
        };
    },
];

export type IAdsGridFactory = AngularInjected<typeof AdsGridFactory>;
export type IAdsGrid = InstanceType<IAdsGridFactory>;

export interface IAdGridDirectiveScope extends angular.IScope {
    grid: IMetricsFunnelNodeGridViewModel;
    model: IAdsGrid;
    organizationId: string;
    gridPagePlatformUrl?: string;
    drilldownModel: {
        enabled: boolean;
        mouseOnTopOfElement: boolean;
        selectedValuesByProperty: Record<string, Set<string | number>>;
    };
    select: (value: string) => void;
    onColumnResize: (columnsResized: Record<string, number>) => void;
    onRowSortChange: (columnSorting: { field: string; order: number }[]) => void;
    onFilterChanged: (columnFilters: Record<string, unknown>) => void;
}

export const AdsGridDirectiveFactory = () => [
    'MetricsFunnelNodeGridViewModel',
    function AdsGridDirective(
        MetricsFunnelNodeGridViewModel: IMetricsFunnelNodeGridViewModelFactory,
    ): angular.IDirective<IAdGridDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                organizationId: '=',
            },
            replace: true,
            template: `
                <article class="marketing-grid-container" ng-class="{error: model.isError}">
                    <div class="marketing-grid-header">
                        <div class="marketing-grid-platform-title">{{ model.title }}</div>
                        <div ng-if="gridPagePlatformUrl" class="more-info-label">
                            <a ng-href="{{ gridPagePlatformUrl }}">click here to see more item info</a>
                        </div>
                    </div>
                    <div class="message-container">
                        <div class="message">
                            <exclamation-triangle class="react-icon"></exclamation-triangle>
                            <span class="message-summary">Data could not be loaded</span>
                        </div>
                    </div>
                    <div class="grid-container" ng-if="grid.options && !model.isError">
                        <div class="ag-42 grid grid-new ag-theme-alpine" ag-grid="grid.options"></div>
                    </div>
                </article>
            `,
            link: function AdsGridDirectiveLink($scope, $element) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/non-nullable-type-assertion-style
                const element = $element[0] as HTMLElement;

                // Just to respect the API
                $scope.select = (value: string) => {};
                $scope.onColumnResize = (columnsResized: Record<string, number>) => {};
                $scope.onRowSortChange = (columnSorting: { field: string; order: number }[]) => {};
                $scope.onFilterChanged = (columnFilters: Record<string, unknown>) => {};

                // Init Grid default state
                // const gridPageUrl = $rootScope.routes.grid?.url ?? $rootScope.routes.items?.url ?? '';
                // $scope.gridPagePlatformUrl = `${gridPageUrl}?platform=${$scope.model.platform}`;

                $scope.drilldownModel = {
                    enabled: false,
                    mouseOnTopOfElement: false,
                    selectedValuesByProperty: {},
                };

                $scope.grid = MetricsFunnelNodeGridViewModel($scope);

                const columns: IColumnDef[] = (() => {
                    return $scope.model.metrics.map(metric => {
                        return {
                            headerGroup: metric.headerGroup,
                            cellFilter: metric.cellFilter ?? '',
                            _cellClass: metric.cellClass ?? '',
                            category: metric.category ?? '',
                        };
                    });
                })();
                const { node } = $scope.model;
                $scope.grid.updateColumns({ node, columns });
                $scope.grid.updateData({ node, data: $scope.model.data, widths: {} });

                const removeExceedingHeight = () => {
                    $scope.grid.options.api?.setDomLayout('autoHeight');
                    // auto height will get the grid to fill the height of the contents,
                    // so the grid div should have no height set, the height is dynamic.
                    if (element.getElementsByClassName('ag-42')[0]) {
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/non-nullable-type-assertion-style
                        (element.getElementsByClassName('ag-42')[0] as HTMLElement).style.height = '';
                    }
                };

                const refresh = () => {
                    if (!$scope.model.data) {
                        return;
                    }

                    $scope.grid.resetColumnDefs();
                    $scope.grid.updateData({
                        node: $scope.model.node,
                        data: $scope.model.data,
                        widths: {},
                    });
                    removeExceedingHeight();
                };

                $scope.$watch('model', () => {
                    const columns: IColumnDef[] = (() => {
                        return $scope.model.metrics.map(metric => {
                            return {
                                category: metric.category ?? '',
                                cellClass: metric.cellClass ?? '',
                                cellFilter: metric.cellFilter ?? '',
                                field: metric.field,
                                headerGroup: metric.headerGroup,
                                headerName: metric.headerName ?? '',
                                _cellClass: String(_.get(metric, '_cellClass') ?? ''),
                            };
                        });
                    })();

                    $scope.grid.updateColumns({ node: $scope.model.node, columns });
                    refresh();
                });

                $scope.$watch('model.data', () => refresh());
            },
        };
    },
];

export const AdsSectionGridDirectiveFactory = () => [
    function AdsSectionGridDirective(): angular.IDirective<
        angular.IScope & { views: IAdView[]; model: IAdView[]; header: ICardHeaderModel }
    > {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                views: '=',
                organizationId: '=',
            },
            template: `
                <div class="marketing-page-grid-container card">
                    <ads-card-header ng-if="header" model="header"></ads-card-header>
                    <div class="marketing-page-grid-block" ng-repeat="adView in model">
                        <ads-grid model="adView.grid" organization-id="organizationId"></ads-grid>
                        <div class="separator" ng-if="!$last"></div>
                    </div>
                </div>
            `,
            link: function AdsSectionGridDirectiveLink($scope) {
                const init = () => {
                    $scope.model = $scope.views.filter(view => typeof view.grid !== 'undefined');
                    $scope.header = {
                        title: `Grid ${$scope.model.length > 1 ? ' Comparison' : ''}`,
                    };
                };

                init();

                $scope.$watch('views', () => Boolean($scope.views) && init());
            },
        };
    },
];
