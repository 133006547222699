import { react2angular } from "react2angular";
import { UsersIcon } from '@heroicons/react/24/outline'
import { IIconProps, parseClassName } from './icons.misc'

const Users = (props: IIconProps) => {
    const className = parseClassName(props);
    return <UsersIcon className={ className }/>;
}

const UsersModule = angular
    .module('42.components.icons.users', [])
    .component('users', react2angular(Users, ['model']));

export const USERS_ICONS = {
    'users': Users,
}

export default UsersModule;
