import { react2angular } from "react2angular";
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { IIconProps, parseClassName } from "./icons.misc";

export const InformationCircle = (props: IIconProps) => {
    const className = parseClassName(props);
    return <InformationCircleIcon className={className} />;
}

const InformationCircleLight = (props: IIconProps) => {
    const InformationCircleLightIcon = (props: {className: string}) => {
        return (
            <svg className={props.className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.25 9.25696L9.29149 9.23621C9.86461 8.94965 10.5099 9.4673 10.3545 10.0889L9.64549 12.925C9.49009 13.5466 10.1354 14.0643 10.7085 13.7777L10.75 13.757M19 10.007C19 14.9775 14.9706 19.007 10 19.007C5.02944 19.007 1 14.9775 1 10.007C1 5.0364 5.02944 1.00696 10 1.00696C14.9706 1.00696 19 5.0364 19 10.007ZM10 6.25696H10.0075V6.26446H10V6.25696Z" stroke="#AAAAAA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }

    const className = parseClassName(props);
    return <InformationCircleLightIcon className={className}/>;
}

const InformationCircleModule = angular
    .module('42.components.icons.information-circle', [])
    .component('informationCircle', react2angular(InformationCircle, ['model']))
    .component('informationCircleLight', react2angular(InformationCircleLight, ['model']));

export const INFORMATION_CIRCLE_ICONS = {
    'information-circle': InformationCircle,
    'information-circle-light': InformationCircleLight,
}

export default InformationCircleModule;
