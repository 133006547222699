import _ from 'lodash';
import { IMetricDefinition } from '../../../lib/types';
import { IQuery } from '../../../lib/types';
import { MultiCheckDropdownItem, MultiCheckDropdownModel } from '../../../components/multi-check-dropdown';
import { IAdView } from '../ads.controller';
import { ICardHeaderModel } from './ads-card-header.directive';
import { AdsPlatformConfig } from '../ads.service';
import { IPropertyDefinition } from '../../../lib/config-hierarchy';
import AdsStorage from '../ads.storage';
import { IMetricDefinitionExtended } from '../ads.helper';
import {
    IEchartsWithQueryViewModel,
    IEchartsWithQueryViewModelFactory,
} from '../../../directives/charts/chart-echarts-with-query-view-model';
import { IBucket } from '../../../components/bucket-picker';

export interface IAdsChartOptions {
    platform: string;
    title: string;
    properties: IPropertyDefinition[];
    property: IPropertyDefinition;
    config: AdsPlatformConfig;
    metrics: IMetricDefinitionExtended[];
    allMetrics: IMetricDefinitionExtended[];
}

export class AdsChart {
    platform: string;
    title: string;
    properties: IPropertyDefinition[];
    property: IPropertyDefinition;
    config: AdsPlatformConfig;
    metrics: IMetricDefinitionExtended[];
    allMetrics: IMetricDefinitionExtended[];
    allMetricsByField: Record<string, IMetricDefinitionExtended>;

    constructor({ platform, title, properties, metrics, allMetrics, property, config }: IAdsChartOptions) {
        this.platform = platform;
        this.title = title;
        this.properties = properties;
        this.metrics = metrics;
        this.allMetrics = allMetrics;
        this.allMetricsByField = _.keyBy(allMetrics, 'field');
        this.property = property;
        this.config = config;
    }

    updateMetrics(selectedMetrics: IMetricDefinitionExtended[]) {
        this.metrics = selectedMetrics;
        void AdsStorage.saveChartStorage(
            this.platform,
            selectedMetrics.map(m => m.field),
        );
    }
}

interface AdsChartDirectiveFactoryScope extends angular.IScope {
    chart: AdsChart | null;
    selectBucket: (bucket: IBucket) => void;
    bucket?: IBucket;
    selectedMetrics: IMetricDefinitionExtended[];
    model: IEchartsWithQueryViewModel;
    dropdown: MultiCheckDropdownModel;
    onMetricsDropdownClick: (item: MultiCheckDropdownItem) => void;
}

export const AdsChartDirectiveFactory = () => [
    '$rootScope',
    'EchartsWithQueryViewModel',
    function AdsChartDirective(
        $rootScope: angular.IRootScopeService & { query: IQuery },
        EchartsWithQueryViewModel: IEchartsWithQueryViewModelFactory,
    ): angular.IDirective<AdsChartDirectiveFactoryScope> {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                chart: '=',
            },
            template: `
                <div class="marketing-chart-container echarts-item-single" ng-class="{error: model.isError}">
                    <div class="card-title marketing-chart-header">
                        <div class="marketing-grid-platform-title">{{ chart.platform }}</div>
                        <div class="marketing-chart-selections">
                            <multi-check-dropdown ng-if="dropdown" model="dropdown"></multi-check-dropdown>
                            <bucket-picker
                                ng-if="selectBucket"
                                select="selectBucket"
                                selected="bucket">
                            </bucket-picker>
                        </div>
                    </div>
                    <div class="message-container">
                        <div class="message">
                            <exclamation-triangle class="react-icon"></exclamation-triangle>
                            <span class="message-summary">Data could not be loaded</span>
                        </div>
                    </div>
                    <div class="empty-selection" ng-if="model.isBlank">
                        <span ng-if="chart.metrics.length === 0 && chart.allMetrics.length > 0">Please select a Metric</span>
                        <span ng-if="chart.metrics.length !== 0 && (!model.data || model.data.length === 0)">No Data</span>
                    </div>
                    <main class="loadable" ng-if="!model.isError" ng-class="{loading: model.isLoading}">
                        <echarts-custom
                            ng-if="!model.isBlank"
                            options="model.options"
                            resizer="setResizer">
                        </echart-custom>

                    </main>
                </div>
            `,
            link: function AdsChartDirectiveLink($scope) {
                let selectedMetrics: IMetricDefinitionExtended[] = _.cloneDeep($scope.chart?.metrics) ?? [];

                $scope.selectBucket = (bucket: IBucket) => {
                    $scope.$applyAsync(() => {
                        $scope.bucket = bucket;
                    });
                };

                const createEchartsWithQueryViewModel = (bucketId: string, title: string) => {
                    const properties = [`calendar.${bucketId}`];
                    if (bucketId === 'week') properties.push('calendar.year');

                    return {
                        title,
                        type: 'line',
                        properties,
                        metrics: selectedMetrics.map(metrics => metrics.field),
                        overrides: {
                            lineChart: {
                                nameFn: (m: IMetricDefinition) => m.headerGroup,
                                areaStyle: null,
                                lineStyle: { width: 1 },
                                multipleYAxis: true,
                                timeRangeAsHeader: true,
                                displayTitle: true,
                                abbreviateYAxis: true,
                                colorsByMetric: selectedMetrics.reduce<Record<string, string | undefined>>(
                                    (acc, metric) => {
                                        acc[metric.field] = metric.color;
                                        return acc;
                                    },
                                    {},
                                ),
                                bucket: bucketId,
                            },
                        },
                    };
                };

                const updateMetrics = (item: MultiCheckDropdownItem) => {
                    const { id } = item;
                    const metricsIds = selectedMetrics.map(m => m.field);
                    const index = metricsIds.findIndex(m => m === id);

                    if (index > -1) {
                        metricsIds.splice(index, 1);
                    } else {
                        metricsIds.push(id);
                    }

                    selectedMetrics = _.compact(metricsIds.map(metricId => $scope.chart?.allMetricsByField[metricId]));
                    $scope.chart?.updateMetrics(selectedMetrics);
                    refresh();
                };

                const refresh = () => {
                    if (!$scope.chart || !$scope.bucket) return;

                    const query = _.cloneDeep($rootScope.query);
                    query.filters = query.filters ?? {};
                    if ($scope.chart.config.filterValue) {
                        const property = $scope.chart.property;
                        const { column, table } = property;
                        if (table && column) {
                            query.filters[table] = {
                                ...(query.filters[table] ?? {}),
                                ...{ [column]: $scope.chart.config.filterValue },
                            };
                        }
                    }

                    $scope.model = new EchartsWithQueryViewModel(
                        createEchartsWithQueryViewModel($scope.bucket.id, $scope.chart.title),
                        query,
                    );

                    const dropdownList = (() => {
                        const metricsByField = _.keyBy(selectedMetrics, 'field');
                        return $scope.chart.allMetrics.reduce((acc: MultiCheckDropdownItem[], metric) => {
                            acc.push({
                                id: metric.field,
                                title: metric.title ?? '',
                                selected: Boolean(metricsByField[metric.field]),
                            });

                            return acc;
                        }, []);
                    })();

                    $scope.dropdown = {
                        list: dropdownList,
                        message: 'Choose the Metrics',
                        onItemClick: (item: MultiCheckDropdownItem) => updateMetrics(item),
                    };
                };

                const unWatchQueryRefresh = $rootScope.$on('query.refresh', refresh);
                $scope.$watch('chart', refresh);
                $scope.$watch('bucket', refresh);
                $scope.$on('$destroy', () => {
                    unWatchQueryRefresh();
                });
            },
        };
    },
];

interface AdsGridDirectiveScope extends angular.IScope {
    views: IAdView[];
    model: IAdView[];
    header: ICardHeaderModel;
}
export const AdsSectionChartDirectiveFactory = () => [
    function AdsSectionChartDirective(): angular.IDirective<AdsGridDirectiveScope> {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                views: '=',
                updateMetrics: '&',
            },
            template: `
                <div class="marketing-page-chart-container card">
                    <ads-card-header ng-if="header" model="header"></ads-card-header>
                    <div class="marketing-page-chart-block" ng-repeat="adView in model">
                        <ads-chart chart="adView.chart" update-metrics="updateMetrics()"></ads-chart>
                        <div class="separator" ng-if="!$last"></div>
                    </div>
                </div>
            `,
            link: function AdsSectionChartDirectiveLink($scope) {
                const init = () => {
                    $scope.model = $scope.views.filter(view => typeof view.chart !== 'undefined');
                    $scope.header = {
                        title: `Chart ${$scope.model.length > 1 ? ' Comparison' : ''}`,
                    };
                };

                init();

                $scope.$watch('views', () => Boolean($scope.views) && init());
            },
        };
    },
];
