import { react2angular } from "react2angular";
import { PhotoIcon } from '@heroicons/react/24/solid'
import { IIconProps, parseClassName } from "./icons.misc";

const Photo = (props: IIconProps) => {
    const className = parseClassName(props);
    return  <PhotoIcon className={className} />;
}

const PhotoModule = angular
    .module('42.components.icons.photo', [])
    .component('photo', react2angular(Photo, ['model']));

export const PHOTO_ICONS = {
    'photo': Photo,
}
export default PhotoModule;
