import './icons.scss';
import { IIconProps } from './icons.misc';
import { react2angular } from 'react2angular';
import ArrowLongLeftModule, { ARROW_LONG_LEFT_ICONS } from './arrow-direction.icon';
import ArrowStartOnRectangleModule, { ARROW_ON_RECTANGLE_ICONS } from './arrow-on-rectangle.icon';
import ArrowTrendingUpModule, { ARROW_TRENDING_UP_ICONS } from './arrow-trending-up.icon';
import ArrowUpTrayModule, { ARROW_UP_TRAY_ICONS } from './arrow-up-tray.icon';
import Bars3Module, { BARS_3_ICONS } from './bars-three.icon';
import BellAlertModule, { BELL_ALERT_ICONS } from './bell-alert.icon';
import BookOpenModule, { BOOK_OPEN_ICONS } from './book-open.icon';
import CalendarDateRangeModule, { CALENDAR_DATE_RANGE_ICONS } from './calendar-date-range.icon';
import ChartsModule, { CHARTS_ICONS } from './charts.icon';
import CheckModule, { CHECK_ICONS } from './check.icon';
import ChevronModule, { CHEVRON_ICONS } from './chevron.icon';
import ClipboardDocumentListModule, { CLIPBOARD_DOCUMENT_ICONS } from './clipboard-document.icon';
import ClockModule, { CLOCK_ICONS } from './clock.icon';
import DocumentTextModule, { DOCUMENT_TEXT_ICONS } from './document-text.icon';
import EllipsisModule, { ELLIPSIS_ICONS } from './ellipsis.icon';
import ExclamationTriangleModule, { EXCLAMATION_TRIANGLE_ICONS } from './exclamation-circle.icon';
import HandsModule, { HANDS_ICONS } from './hands.icon';
import InboxArrowModule, { INBOX_ARROW_ICONS } from './inbox-arrow.icon';
import InformationCircleModule, { INFORMATION_CIRCLE_ICONS } from './information-circle.icon';
import LightBulbModule, { LIGHT_BULB_ICONS } from './light-bulb.icon';
import MagnifyingGlassModule, { MAGNIFYING_GLASS_ICONS } from './magnifying-glass.icon';
import PaperAirplaneModule, { PAPER_AIRPLANE_ICONS } from './paper-plane.icon';
import PencilModule, { PENCIL_ICONS } from './pencil.icon';
import PhotoModule, { PHOTO_ICONS } from './photo.icon';
import PlusCircleModule, { PLUS_ICONS } from './plus-circle.icon';
import ShareModule, { SHARE_ICONS } from './share.icon';
import TvModule, { TV_ICONS } from './tv.icon';
import UsersModule, { USERS_ICONS } from './users.icon';
import XCircleModule, { X_CIRCLE_ICONS } from './x-circle.icon';
import XMarkModule, { X_MARK_ICONS } from './x-mark.icon';

const module = angular.module('42.components.icons', [
    ArrowLongLeftModule.name,
    ArrowStartOnRectangleModule.name,
    ArrowTrendingUpModule.name,
    ArrowUpTrayModule.name,
    Bars3Module.name,
    BellAlertModule.name,
    BookOpenModule.name,
    CalendarDateRangeModule.name,
    ChartsModule.name,
    CheckModule.name,
    ChevronModule.name,
    ClipboardDocumentListModule.name,
    ClockModule.name,
    DocumentTextModule.name,
    EllipsisModule.name,
    ExclamationTriangleModule.name,
    HandsModule.name,
    InboxArrowModule.name,
    InformationCircleModule.name,
    LightBulbModule.name,
    MagnifyingGlassModule.name,
    PaperAirplaneModule.name,
    PencilModule.name,
    PhotoModule.name,
    PlusCircleModule.name,
    ShareModule.name,
    TvModule.name,
    UsersModule.name,
    XCircleModule.name,
    XMarkModule.name,
]);

const All_ICONS: Record<string, (props: IIconProps) => JSX.Element> = {
    ...ARROW_LONG_LEFT_ICONS,
    ...ARROW_ON_RECTANGLE_ICONS,
    ...ARROW_TRENDING_UP_ICONS,
    ...ARROW_UP_TRAY_ICONS,
    ...BARS_3_ICONS,
    ...BELL_ALERT_ICONS,
    ...BOOK_OPEN_ICONS,
    ...CALENDAR_DATE_RANGE_ICONS,
    ...CHARTS_ICONS,
    ...CHECK_ICONS,
    ...CHEVRON_ICONS,
    ...CLIPBOARD_DOCUMENT_ICONS,
    ...CLOCK_ICONS,
    ...DOCUMENT_TEXT_ICONS,
    ...ELLIPSIS_ICONS,
    ...EXCLAMATION_TRIANGLE_ICONS,
    ...HANDS_ICONS,
    ...INBOX_ARROW_ICONS,
    ...INFORMATION_CIRCLE_ICONS,
    ...LIGHT_BULB_ICONS,
    ...MAGNIFYING_GLASS_ICONS,
    ...PAPER_AIRPLANE_ICONS,
    ...PENCIL_ICONS,
    ...PHOTO_ICONS,
    ...PLUS_ICONS,
    ...SHARE_ICONS,
    ...TV_ICONS,
    ...USERS_ICONS,
    ...X_CIRCLE_ICONS,
    ...X_MARK_ICONS,
};

export const ICONS_KEYS = Object.keys(All_ICONS);

export interface IGenericIconProps extends IIconProps {
    icon: string;
}

const GenericIcon = (props: IGenericIconProps) => {
    const { icon, model } = props;
    const Icon = All_ICONS[icon];
    if (!Icon) throw new Error(`Icon not found: ${icon}`);
    return Icon({ model: model ?? {} });
};

module.component('genericIcon', react2angular(GenericIcon, ['icon', 'model']));

export default module;
