import { react2angular } from "react2angular";
import { InboxArrowDownIcon } from '@heroicons/react/24/outline'
import { IIconProps, parseClassName } from "./icons.misc";

const InboxArrowDown = (props: IIconProps) => {
    const className = parseClassName(props);
    return <InboxArrowDownIcon className={className} />;
}

const InboxArrowModule = angular
    .module('42.components.icons.inbox-arrow', [])
    .component('inboxArrowDown', react2angular(InboxArrowDown, ['model']));

export const INBOX_ARROW_ICONS = {
    'inbox-arrow-down': InboxArrowDown,
}

export default InboxArrowModule;
