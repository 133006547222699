import { react2angular } from "react2angular";
import { ClockIcon } from '@heroicons/react/24/outline'
import { IIconProps, parseClassName } from './icons.misc'

const Clock = (props: IIconProps) => {
    const className = parseClassName(props);
    return <ClockIcon className={className} />;
}

const ClockModule = angular
    .module('42.components.icons.Clock', [])
    .component('clock', react2angular(Clock, ['model']));

export const CLOCK_ICONS = {
    'clock': Clock,
}

export default ClockModule;
