import { react2angular } from "react2angular";
import { CalendarDateRangeIcon } from '@heroicons/react/24/solid'
import { IIconProps, parseClassName } from "./icons.misc";

const CalendarDateRange = (props: IIconProps) => {
    const className = parseClassName(props);
    return <CalendarDateRangeIcon className={ className }/>;
}

const CalendarDateRangeModule = angular
    .module('42.components.icons.calendarDateRange', [])
    .component('calendarDateRange', react2angular(CalendarDateRange, ['model']));

export const CALENDAR_DATE_RANGE_ICONS = {
    'calendar-date-range': CalendarDateRange,
}

export default CalendarDateRangeModule;
