import { react2angular } from "react2angular";
import { ArrowRightStartOnRectangleIcon, ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/outline'
import { IIconProps, parseClassName } from "./icons.misc";

const ArrowRightStartOnRectangle = (props: IIconProps) => {
    const className = parseClassName(props);
    return <ArrowRightStartOnRectangleIcon className={className} />;
}
const ArrowLeftStartOnRectangle = (props: IIconProps) => {
    const className = parseClassName(props);
    return <ArrowLeftStartOnRectangleIcon className={className} />;
}

const ArrowStartOnRectangleModule = angular
    .module('42.components.icons.ArrowRightStartOnRectangle', [])
    .component('arrowRightStartOnRectangle', react2angular(ArrowRightStartOnRectangle, ['model']))
    .component('arrowLeftStartOnRectangle', react2angular(ArrowLeftStartOnRectangle, ['model']))

export const ARROW_ON_RECTANGLE_ICONS = {
    'arrow-right-start-on-rectangle': ArrowRightStartOnRectangle,
    'arrow-left-start-on-rectangle': ArrowLeftStartOnRectangle,
}

export default ArrowStartOnRectangleModule;
