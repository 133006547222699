import { IOutsideElementClick } from '../directives/outside-element-click.directive';
export interface MultiCheckDropdownItem {
    id: string;
    title: string;
    selected?: boolean;
}

export interface MultiCheckDropdownModel {
    list: MultiCheckDropdownItem[];
    message: string;
    onItemClick: (item: MultiCheckDropdownItem) => void;
}

interface MultiCheckDropdownDirectiveScope extends angular.IScope {
    message: string;
    model: MultiCheckDropdownModel;
    onClick: (item: MultiCheckDropdownItem) => void;
    opened: boolean;
    toggle: () => void;
    onItemClick: ($event: Event, item: MultiCheckDropdownItem) => void;
}

export const MultiCheckDropdownDirective = () => [
    'OutsideElementClick',
    function MultiCheckDropdownDirective(
        OutsideElementClick: IOutsideElementClick,
    ): angular.IDirective<MultiCheckDropdownDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <div class="multi-check-dropdown">
                    <div class="multi-check-dropdown-header" ng-class="{'opened': opened }" ng-click="toggle()">
                        <label class="multi-check-dropdown-header-title">{{ model.message }}</label>
                        <chevron-down-micro class="react-icon" model="'icon-14 light-grey'"></chevron-down-micro>
                    </div>
                    <div class="multi-check-dropdown-options" ng-if="opened">
                        <div class="multi-check-dropdown-item"
                            ng-repeat="item in model.list"
                            ng-click="onItemClick($event, item)"
                            ng-class="{'selected': item.selected }">
                            <div class="multi-check-dropdown-check">
                                <check class="react-icon" model="'icon-12 blue'" ng-if="item.selected"></check-micro>
                            </div>
                            <div class="multi-check-dropdown-item-title">{{ item.title }}</div>
                        </div>
                    </div>
                </div>
            `,
            link: function MultiCheckDropdownLink($scope, $element) {
                $scope.message ??= 'Choose the Option';
                $scope.opened = false;

                let outsideElementClickCleanup = () => {};

                $scope.toggle = () => {
                    $scope.opened = !$scope.opened;

                    if ($scope.opened) {
                        outsideElementClickCleanup = OutsideElementClick($scope, $element, () => {
                            $scope.opened && $scope.toggle();
                        });
                    } else {
                        outsideElementClickCleanup();
                    }
                };

                $scope.onItemClick = ($event, item) => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();
                    item.selected = !item.selected;
                    $scope.model.onItemClick(item);
                };
            },
        };
    },
];

const multiCheckDropdownModule = angular
    .module('42.components.multi-check-dropdown', [])
    .directive('multiCheckDropdown', MultiCheckDropdownDirective());

export default multiCheckDropdownModule;
