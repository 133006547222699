import _ from 'lodash';
import type { AngularInjected } from '../../../lib/angular';
import { IQueryServiceAPI } from '../../../modules/services/query-service.types';
import type { IMetricDefinition, IQuery } from '../../../lib/types';
import { ConfigAPI } from '../../../lib/config-api';
import type { IPropertyDefinition } from '../../../lib/config-hierarchy';
import { HierarchyService } from '../../../modules/hierarchy';
import type { IQueryMetrics } from '../../main-controller';
import type { IOverviewStat } from '../overview-card-charts';
import { IOverviewChartMetricsConfigService, joinMetricIdsWithMetricDescriptors } from '../overview-metrics.service';
import { normalizeDefaultsItemsGroupByConfig } from '../config-views-sales';
import { ITopItemsConfig, normalizeTopItemsConfig } from './card-top-items-config';
import { ItemCellRenderer } from '../../../components/item-cell-renderer';

export interface ITopItemsProperty {
    id: string;
    label: string;
}

export interface ITopItemsResolvedConfig {
    properties: ITopItemsProperty[];
    metrics: IMetricDefinition[];
    sort: IMetricDefinition[] | null;
    limit: number;
}

export const DEFAULT_GROUP_BY_ITEM_PROPERTY = 'items.name';
export const DEFAULT_TOP_ITEMS_LIMIT = 6;

export type IOverviewTopItemsConfigService = AngularInjected<typeof OverviewTopItemsConfigServiceFactory>;
export const OverviewTopItemsConfigServiceFactory = () => [
    '$q',
    'QueryMetrics',
    'OverviewChartMetricsConfigService',
    function OverviewTopItemsConfigService(
        $q: angular.IQService,
        QueryMetrics: IQueryMetrics,
        OverviewChartMetricsConfigService: IOverviewChartMetricsConfigService,
    ) {
        const fetchHierarchy = () => {
            return $q.when(HierarchyService().fetch()).then(({ all, stores, items }) => {
                return _.keyBy(
                    _.flatten([stores, items, all].map(propertiesSet => Object.values(propertiesSet))),
                    'id',
                );
            });
        };

        const joinPropertiesWithHierarchy = (
            availableProperties: Record<string, IPropertyDefinition>,
            properties: string[],
        ): ITopItemsProperty[] => {
            return properties.map(property => {
                const newProperty = availableProperties[property] ?? {
                    id: property,
                    plural: property,
                    label: property,
                };
                const label = newProperty.plural ?? newProperty.label ?? newProperty.id;
                return { id: newProperty.id, label };
            });
        };

        const fetchDefaultKpis = (
            salesChartMetrics: IMetricDefinition[] | null,
            availableMetrics: IMetricDefinition[],
            salesChartSelectedConfigKpis: { field: string; [x: string]: unknown }[] = [],
        ) => {
            const config = joinMetricIdsWithMetricDescriptors(availableMetrics, salesChartSelectedConfigKpis);
            const salesKpis = config.filter(item => ['Sales', 'Inventory', 'Demand'].includes(item.category ?? ''));
            const kpis = (salesChartMetrics ?? salesKpis).slice(0, 6);
            return kpis.map(x => x.field);
        };

        const getTopItemsConfig = (
            userConfig: unknown,
            orgConfig: unknown,
            salesChartMetrics: IMetricDefinition[] | null,
            availableMetrics: IMetricDefinition[],
        ) => {
            const defaults = {
                kpis: fetchDefaultKpis(salesChartMetrics, availableMetrics),
                properties: normalizeDefaultsItemsGroupByConfig(userConfig) ??
                    normalizeDefaultsItemsGroupByConfig(orgConfig) ?? [DEFAULT_GROUP_BY_ITEM_PROPERTY],
            };
            const userTopItemsConfig = normalizeTopItemsConfig(userConfig, defaults);
            const orgTopItemsConfig = normalizeTopItemsConfig(orgConfig, defaults);

            const defaultConfig: ITopItemsConfig = {
                properties: defaults.properties,
                kpis: defaults.kpis,
                limit: DEFAULT_TOP_ITEMS_LIMIT,
                sort: null,
            };

            return userTopItemsConfig ?? orgTopItemsConfig ?? [defaultConfig];
        };

        return {
            fetch: () => {
                const configPromise = ConfigAPI.get();
                return $q
                    .all([
                        QueryMetrics.fetch(),
                        fetchHierarchy(),
                        OverviewChartMetricsConfigService.fetch(),
                        configPromise.then(config => config.user.getInternal()),
                        configPromise.then(config => config.organization.get()),
                    ])
                    .then(([availableMetrics, availableProperties, salesChartMetrics, userConfig, orgConfig]) => {
                        const overviewTopItemsConfig = getTopItemsConfig(
                            userConfig,
                            orgConfig,
                            salesChartMetrics,
                            availableMetrics,
                        );

                        const topItemsConfigs: ITopItemsResolvedConfig[] = overviewTopItemsConfig.map(config => {
                            const metrics = (() => {
                                const metrics = joinMetricIdsWithMetricDescriptors(availableMetrics, config.kpis);
                                return QueryMetrics.applyCurrencyToMetrics(metrics);
                            })();

                            const properties = joinPropertiesWithHierarchy(availableProperties, config.properties);
                            const sort = (() => {
                                const sort = config.sort;
                                if (_.isNil(sort)) return null;
                                const metrics = joinMetricIdsWithMetricDescriptors(availableMetrics, sort);
                                return QueryMetrics.applyCurrencyToMetrics(metrics);
                            })();

                            return { metrics, properties, sort, limit: config.limit };
                        });

                        return topItemsConfigs;
                    });
            },
        };
    },
];

export type ITopItemsService = AngularInjected<typeof TopItemsServiceFactory>;
export const TopItemsServiceFactory = () => [
    '$q',
    'QueryServiceAPI',
    function TopItemsService($q: angular.IQService, QueryServiceAPI: IQueryServiceAPI) {
        return {
            fetch: (query: IQuery, config: ITopItemsResolvedConfig) => {
                const properties = config.properties.map(property => property.id);
                query = _.cloneDeep(query);
                delete query.comparison;
                delete query.sort;

                query.limit = config.limit;

                // FIXME: Do we truly want to allow the caller to set options here?
                query.options ??= {};
                query.options.includeTotals = false;
                query.options.properties = properties;
                query.options.metrics = config.metrics.map(metric => metric.field);

                // FIXME: What happens when config.sort is undefined / null...?
                query.options.sort = config.sort?.map(metric => ({
                    property: properties[0],
                    field: metric.field,
                    limit: config.limit,
                    order: -1,
                }));

                return $q.when(QueryServiceAPI().then(api => api.query.metricsFunnel(query)));
            },
        };
    },
];

interface TopItemViewItemDirectiveScope extends angular.IScope {
    model: undefined | ITopItemsViewModelViewItem;
}

export const TopItemViewItemDirectiveFactory = () => [
    '$filter',
    function TopItemViewItemDirective(
        $filter: angular.IFilterService,
    ): angular.IDirective<TopItemViewItemDirectiveScope> {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                model: '=',
            },
            template: '<div class="top-item-view-item"></div>',
            link: function TopItemViewItemLink(scope, $element) {
                const element = $element[0];
                if (!element) throw new Error('Could not find element');

                const renderer = new ItemCellRenderer($filter);

                const getHtml = (model: NonNullable<TopItemViewItemDirectiveScope['model']>) => {
                    const item = {
                        ..._.pickBy(model.data, (v, k) => k.startsWith('item_')),
                        ...[
                            ...model.properties.map((x, i) => ({ [`property${i}`]: x.value })),
                            ...model.metrics.map(x => ({ [x.metric.field]: x.value })),
                        ].reduce((a, b) => ({ ...a, ...b }), {}),
                    };
                    const label = model.properties
                        .filter(x => typeof x.value === 'string' || typeof x.value === 'number')
                        .map(x => String(x.value))
                        .join(' / ');
                    renderer.init({
                        item: item,
                        label: label,
                        availableMetrics: model.metrics.map(x => x.metric),
                        selectedMetrics: model.metrics.map(x => x.metric.field),
                    });
                    return renderer.getGui();
                };

                scope.$watch('model', (model: undefined | TopItemViewItemDirectiveScope['model']) => {
                    element.replaceChildren();
                    if (!model) return;
                    element.replaceChildren(getHtml(model));
                });
            },
        };
    },
];

export const CardTopItemsDirectiveFactory = () => [
    function CardTopItemsDirective(): angular.IDirective<angular.IScope & { itemsUrl?: string; model?: unknown }> {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                itemsUrl: '=',
                model: '=',
            },
            template: `
                <div class="top-items card" ng-class="{loading: model.view.isLoading, empty: model.view.items.length == 0, error: model.view.isError }">

                    <div ng-if="model.view.isError && !model.view.isLoading" class="message-container">
                        <div class="message">
                            <exclamation-triangle class="react-icon"></exclamation-triangle>
                            <span class="message-summary">Data could not be loaded</span>
                        </div>
                    </div>

                    <div class="top-items-header" ng-if="!model.view.isError">
                        <div class="header-label">
                            <h1 class="card-title">
                                <span class="metric" ng-repeat="x in model.view.properties track by x.id">{{ x.label }}</span>
                                <span ng-show="model.view.properties && model.view.sort">by</span>
                                <span class="metric" ng-repeat="x in model.view.sort track by x.id">{{ x.label }}</span>
                            </h1>
                        </div>
                        <div class="more-info-label">
                            <a ng-href="{{ itemsUrl }}">click here to see more item info</a>
                        </div>
                    </div>
                    <section class="items">
                        <ul class="top-items-list">
                            <li class="item-container" ng-repeat="item in model.view.items track by item.id">
                                <top-item-view-item model="item"></top-item-view-item>
                            </li>
                        </ul>
                    </section>
                </div>
            `,
            link: function CardTopItemsDirectiveLink(scope, $element) {
                const TOP_ITEMS_MIN_WIDTH = 640;
                const listEl = $element[0]?.querySelector('.items > .top-items-list');
                const containerEl = listEl?.parentElement;
                if (!listEl || !containerEl) throw new Error('Could not find top items list element');
                const resizeObserver = new ResizeObserver(elements => {
                    const containerWidth = elements[0]?.contentRect?.width ?? 0;
                    containerWidth <= TOP_ITEMS_MIN_WIDTH
                        ? listEl.classList.add('collapsed')
                        : listEl.classList.remove('collapsed');
                });
                resizeObserver.observe(containerEl);
                scope.$on('$destroy', () => resizeObserver.disconnect());
            },
        };
    },
];

type ITopItemsViewModelViewItem = {
    id: string;
    properties: { property: ITopItemsProperty; value: unknown }[];
    metrics: { metric: IMetricDefinition; value: unknown }[];
    data: Record<string, unknown>;
};

export interface ITopItemsViewModelView {
    readonly properties: ITopItemsProperty[];
    readonly sort: { id: string; label: string }[] | null | undefined;
    isLoading: boolean;
    isError: boolean;
    items: null | ITopItemsViewModelViewItem[];
}

export interface ITopItemsViewModel {
    readonly config: ITopItemsResolvedConfig;
    readonly sort: IMetricDefinition[] | null | undefined;
    readonly metrics: IMetricDefinition[] | undefined;
    readonly properties: ITopItemsProperty[] | null | undefined;
    readonly view: ITopItemsViewModelView;
}

export interface ITopItemsViewFactoryOptions {
    query: IQuery;
    config: ITopItemsResolvedConfig[];
    chart: IOverviewStat;
}

export type TopItemsView = AngularInjected<typeof TopItemsViewFactory>;
export type ITopItemsView = InstanceType<TopItemsView>;
export const TopItemsViewFactory = () => [
    'TopItemsService',
    function TopItemsViewFactory(TopItemsService: ITopItemsService) {
        class TopItemsViewModel implements ITopItemsViewModel {
            readonly sort: IMetricDefinition[] | null;
            readonly metrics: IMetricDefinition[];
            readonly properties: ITopItemsProperty[];
            readonly config: ITopItemsResolvedConfig;
            readonly view: ITopItemsViewModelView;

            constructor(query: IQuery, config: ITopItemsResolvedConfig) {
                this.config = _.cloneDeep(config);
                this.sort = config.sort;
                this.metrics = config.metrics;
                this.properties = config.properties;
                const sort =
                    config.sort &&
                    _.uniqBy(config.sort, x => x.field).map(x => ({ id: x.field, label: x.headerGroup }));
                this.view = {
                    items: null,
                    properties: this.properties,
                    isLoading: true,
                    isError: false,
                    sort,
                };
                void this.init(query);
            }

            protected init(query: IQuery) {
                const config = _.cloneDeep(this.config);
                this.view.isLoading = true;
                this.view.isError = false;
                return TopItemsService.fetch(query, config)
                    .then(rows => {
                        rows = Array.isArray(rows) ? rows : [rows];
                        this.view.items = rows.map(data => {
                            const properties = this.properties.map((property, index) => ({
                                property,
                                value: data[`property${index}`],
                            }));
                            const metrics = this.metrics.map(metric => ({
                                metric,
                                value: data[metric.field],
                            }));
                            const id = properties.map(x => String(x.value)).join(':');
                            return { id, data, properties, metrics };
                        });
                    })
                    .then(result => {
                        this.view.isLoading = false;
                        this.view.isError = false;
                        return result;
                    })
                    .catch(error => {
                        this.view.isLoading = false;
                        this.view.isError = true;
                        throw error;
                    });
            }
        }

        class TopItemsView {
            readonly config: ITopItemsResolvedConfig[];
            readonly items: ITopItemsViewModel[];
            constructor({ config, chart, query }: ITopItemsViewFactoryOptions) {
                this.config = config;
                this.items = config.map(c => {
                    const field = chart.actual.field;
                    const headerGroup = chart.title;
                    const sort = c.sort ?? [{ field, headerGroup }];
                    return new TopItemsViewModel(query, { ...c, sort });
                });
            }
        }

        return TopItemsView;
    },
];

export default angular
    .module('42.controllers.overview.card-top-items', ['42.filters'])
    .service('OverviewTopItemsConfigService', OverviewTopItemsConfigServiceFactory())
    .directive('cardTopItems', CardTopItemsDirectiveFactory())
    .directive('topItemViewItem', TopItemViewItemDirectiveFactory())
    .service('TopItemsService', TopItemsServiceFactory())
    .factory('TopItemsView', TopItemsViewFactory());
