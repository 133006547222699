import angular from 'angular';
import { IOutsideElementClick } from '../../directives/outside-element-click.directive';
import { Routes } from '../../router';
import { IOrganization } from '../../lib/types';

export const UserMenuDirective = () => [
    function UserMenu() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <article class="user-menu dropdown">

                    <!-- this is the section that's visible in the top bar -->
                    <article class="user-menu-face dropdown-toggle">
                        <img
                            class="organization-logo"
                            ng-src="{{ model.organization.logo }}"
                            on-error-src="onLogoError()"
                            ng-if="!logoError && model.organization.logo"
                        >
                        <section class="labels" ng-class="{ 'no-logo': logoError || !model.organization.logo }">
                            <span class="organization-label">{{ model.organization.label }}</span>
                            <span class="user-label">{{ model.user.name || model.user.email }}</span>
                        </section>
                        <bars-3-micro class="react-icon user-menu-icon hide-small" model="'icon-18 white'"></bars-3-micro>
                    </article>

                    <!-- enabled if user has multiple organizations -->
                    <ul class="user-menu-dropdown dropdown-menu" ng-if="model.organizations.length > 1">
                        <li class="submenu">
                            <h1>Available Dashboards</h1>
                            <user-menu-organization-list model="model"></user-menu-organization-list>
                        </li>
                        <li class="submenu">
                            <h1>Actions</h1>
                            <user-menu-actions model="model"></user-menu-actions>
                        </li>
                    </ul>

                    <!-- enabled if user has only one org -->
                    <ul class="user-menu-dropdown dropdown-menu" ng-if="model.organizations.length == 1">
                        <li class="submenu">
                            <user-menu-actions model="model"></user-menu-actions>
                        </li>
                    </ul>
                </article>
            `,
            link: function UserMenuLink(
                $scope: angular.IScope & {
                    logoError: boolean;
                    onLogoError: () => void;
                },
            ) {
                $scope.logoError = false;

                $scope.onLogoError = () => ($scope.logoError = true);
            },
        };
    },
];

export const UserMenuOrganizationListDirective = () => [
    '$document',
    'OutsideElementClick',
    function UserMenuOrganizationList($document: angular.IDocumentService, OutsideElementClick: IOutsideElementClick) {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <div class="organizations">
                    <div class="user-menu-organizations-search-box filter-input-search"
                        ng-if="model.organizations.length>4">
                        <input
                            type="text"
                            spellcheck="false"
                            placeholder="search..."
                            ng-model="search.id"
                        >
                        <magnifying-glass-micro class="react-icon orgs-search-icon" model="'icon-16 white'"></magnifying-glass-micro>
                    </div>
                    <ul>
                        <li class="dropdown-menu-item"
                            ng-repeat="organization in model.organizations | orderBy: 'label' | fuzzyBy: 'label': search.id"
                            ng-click="model.setOrganization(organization.id)"
                            ng-class="{active: organization.id == model.organization.id}">
                            {{ organization.label }}
                        </li>
                    </ul>
                </div>
            `,
            link: function UserMenuOrganizationListLink(
                $scope: angular.IScope & { searchIcon: { className: string }; search: { id: string } },
            ) {
                $scope.search = { id: '' };
                const $dropdownElement = $document.find('.user-menu-dropdown.dropdown-menu');
                OutsideElementClick($scope, $dropdownElement, () => {
                    $scope.search.id = '';
                });
            },
        };
    },
];

export const UserMenuActionsDirective = () => [
    '$location',
    'ROUTES',
    function UserMenuActions($location: angular.ILocationService, ROUTES: Routes) {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <ul class="user-menu-actions">
                    <li class="reporting" ng-click="goToCounterPage()" ng-if="model.flags.kiosk">
                        <tv class="react-icon" model="actionsIconModel"></tv>
                        <span>Kiosk Mode</span>
                    </li>
                    <li class="reporting" ng-click="goToReportingPage()" ng-if="model.flags.reporting">
                        <clipboard-document-list class="react-icon" model="actionsIconModel"></clipboard-document-list
                        <span>Reporting</span>
                    </li>
                    <li class="admin" ng-click="goToUserAdminPage()" ng-if="model.user.isAdmin() && model.adminUrl">
                        <users class="react-icon" model="actionsIconModel" ></users>
                        <span>User Admin</span>
                    </li>
                    <li class="logout" ng-click="model.logout()">
                        <arrow-right-start-on-rectangle class="react-icon" model="actionsIconModel"></arrow-right-start-on-rectangle>
                        <span>Logout</span>
                    </li>
                </ul>
            `,
            link: (
                scope: angular.IScope & {
                    goToUserAdminPage: () => void;
                    goToReportingPage: () => void;
                    goToCounterPage: () => void;
                    actionsIconModel: { className: string };
                    model: {
                        adminUrl: string;
                        organization: IOrganization;
                    };
                },
            ) => {
                scope.actionsIconModel = {
                    className: 'icon-14 user-menu-actions-icon',
                };
                scope.goToUserAdminPage = () => {
                    window.open(`${scope.model.adminUrl}/organizations/${scope.model.organization.id}/users`);
                };
                scope.goToReportingPage = () => {
                    if (ROUTES.reportingReports?.url) $location.path(ROUTES.reportingReports.url);
                };
                scope.goToCounterPage = () => {
                    if (ROUTES.counter?.url) $location.path(ROUTES.counter.url);
                };
            },
        };
    },
];
