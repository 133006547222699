export type IIconPropsModel = {
    className?: string;
};
export type IIconProps = {
    model?: IIconPropsModel | string;
};

function containsExactMatch(str: string, search: string): boolean {
    const regex = new RegExp(`\\b${search}\\b`);
    return regex.test(str);
}

export const parseClassName = (props: IIconProps | { model: string }) => {
    const className = (() => {
        if (typeof props.model === 'string') {
            return props.model;
        }
        return typeof props.model?.className === 'string' ? props.model.className : '';
    })();
    return className;

    // return containsExactMatch(className, 'react-icon') ? className : `react-icon ${className}`;
};
