import { react2angular } from "react2angular";
import { ArrowTrendingUpIcon } from '@heroicons/react/24/outline'
import { IIconProps, parseClassName } from "./icons.misc";

const ArrowTrendingUp = (props: IIconProps) => {
    const className = parseClassName(props);
    return <ArrowTrendingUpIcon className={className} />;
}

const ArrowTrendingUpModule = angular
    .module('42.components.icons.arrow-trending-up', [])
    .component('arrowTrendingUp', react2angular(ArrowTrendingUp, ['model']));

export const ARROW_TRENDING_UP_ICONS = {
    'arrow-trending-up': ArrowTrendingUp,
}

export default ArrowTrendingUpModule;
