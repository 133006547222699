import { CurrencyModelService } from './currency.service';

const module = angular.module('42.modules.currency', []);

module.service('UserCurrencyModel', [
    '$q',
    function UserCurrencyModel($q: angular.IQService) {
        return {
            fetch: () => $q.when(CurrencyModelService.fetch()),
        };
    },
]);

module.directive('queryModifiers', [
    function () {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <article class="query-modifiers hide-small">
                    <query-modifier-currency model="model"></query-modifier-currency>
                </article>
            `,
        };
    },
]);

module.directive('queryModifierCurrency', [
    function () {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                small: '=',
            },
            replace: true,
            template: `
                <article class="query-modifier query-modifier-currency">
                    <article class="currency-switcher" ng-if="!small">
                        <span class="currency-switcher-title">
                            Display Currency
                        </span>
                        <div class="currency-switcher-select-header">
                            <chevron-down-micro class="react-icon" model="'light-grey'"></chevron-down-micro>
                            <select ng-model="model.selected"
                                ng-options="currency.label for currency in model.available | orderBy:'label'">
                            </select>
                        </div>
                    </article>
                    <article class="currency-switcher-select" ng-if="small">
                        <div class="select-title">
                            Display Currency
                        </div>
                        <div class="currency-switcher-dropdown">
                            <chevron-down-micro class="react-icon" model="'light-grey'"></chevron-down-micro>
                            <select ng-model="model.selected"
                                ng-options="currency.shortLabel for currency in model.available | orderBy:'label'">
                            </select>
                        </div>
                    </article>
                </article>
            `,
        };
    },
]);
