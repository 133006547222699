import _ from 'lodash'
import { createSortable } from '../../lib/dom/sortable'
import { ToggleModel } from '../../lib/model/model-toggle'

module = angular.module('42.modules.libs.ui', [
    '42.modules.libs.utils'
])
export default module

module.constant("DropdownConfig", {
    openClass: "open"
})

module.service("DropdownService", ['$document', ($document) ->
    openScope = null

    @open = (dropdownScope) ->
        unless openScope
            $document.on("click", closeDropdown)
            $document.on("keydown", escapeKeyBind)
        openScope.isOpen = false if openScope and openScope isnt dropdownScope
        openScope = dropdownScope

    @close = (dropdownScope) ->
        return if openScope isnt dropdownScope
        openScope = null
        $document.off("click", closeDropdown)
        $document.off("keydown", escapeKeyBind)
        return

    isSelfTarget = ($element, event) ->
        try return $element.toArray().find((element) -> event.target is element or element.contains(event.target))
        catch error
            console.error(error)
            return false

    closeDropdown = (event) ->
        return if event and isSelfTarget(openScope?.ctrl?.$element, event)
        openScope.$apply ->
            openScope.isOpen = false
            return

    escapeKeyBind = (evt) ->
        return if evt.which isnt 27
        openScope.focusToggleElement()
        closeDropdown()
        return

    return
])

module.controller("DropdownController", ($scope, $attrs, $parse, DropdownConfig, DropdownService, $animate) ->
    self = this
    scope = $scope.$new()
    openClass = DropdownConfig.openClass
    getIsOpen = undefined
    setIsOpen = angular.noop
    toggleInvoker = (if $attrs.onToggle then $parse($attrs.onToggle) else angular.noop)

    @init = (element) ->
        self.$element = element
        if $attrs.isOpen
            getIsOpen = $parse($attrs.isOpen)
            setIsOpen = getIsOpen.assign
            $scope.$watch getIsOpen, (value) ->
                scope.isOpen = !!value

    @toggle = (open) ->
        scope.isOpen = (if arguments.length then !!open else not scope.isOpen)

    @isOpen = ->
        scope.isOpen

    scope.focusToggleElement = ->
        self.toggleElement[0].focus() if self.toggleElement

    scope.$watch "isOpen", (isOpen) ->
        if isOpen then self.$element[0].classList.add(openClass) \
                  else self.$element[0].classList.remove(openClass)
        if isOpen
            scope.focusToggleElement()
            DropdownService.open(scope)
        else
            DropdownService.close(scope)
        setIsOpen($scope, isOpen)
        toggleInvoker($scope, {open: !!isOpen})

    $scope.$on "$locationChangeSuccess", ->
        scope.isOpen = false

    $scope.$on "$destroy", ->
        scope.$destroy()

    return
)

module.directive("dropdown", ->
    restrict: "CA"
    controller: "DropdownController",
    controllerAs: 'ctrl',
    link: (scope, element, attrs, dropdownCtrl) ->
        scope.ctrl.init(element)
        return
)

module.directive("dropdownToggle", ->
    restrict: "CA"
    require: "?^dropdown"
    link: (scope, element, attrs, dropdownCtrl) ->
        return if not dropdownCtrl
        dropdownCtrl.toggleElement = element

        scope.toggleDropdown = toggleDropdown = (event) ->
            event.preventDefault()
            event.stopPropagation()
            if not element.hasClass("disabled") and not attrs.disabled
                scope.$apply -> dropdownCtrl.toggle()

        # WAI-ARIA
        element.attr({
            "aria-haspopup": true
            "aria-expanded": false
        })

        scope.$watch dropdownCtrl.isOpen, (isOpen) ->
            element.attr("aria-expanded", String(Boolean(isOpen)))

        element.bind("click", toggleDropdown)
        scope.$on("$destroy", -> element.unbind("click", toggleDropdown))

        return
)

module.controller('TabsController', ($scope) ->
    @select = (value) ->
        $scope.selected = value
    @isSelected = (value) ->
        $scope.selected is value
    return
)

module.directive('uiTabs', ->
    restrict: 'E'
    scope:
        selected: '='
        list: '='
    replace: true
    transclude: true
    controller: "TabsController"
    template: \
    """
    <div class="ui-tabs">
        <ul ng-transclude></ul>
    </div>
    """
    link: (scope) ->
        scope.select = (value) -> scope.selected = value
        return
)

module.directive('uiTab', ->
    restrict: 'E'
    scope:
        value: '=tabValue'
        selectTab: '='
    require: '^uiTabs'
    replace: true
    transclude: true
    controller: "TabsController"
    template: \
    """
    <li class="ui-tab-container"
        ng-class="{'selected':isSelected()}"
        ng-click="select()">
        <div class="ui-tab" ng-transclude></div>
    </li>
    """
    link: (scope, element, attributes, controller) ->
        scope.select = ->
            if scope.selectTab
                scope.selectTab(scope.value?.id)
                return
            controller?.select(scope.value)
        scope.isSelected = ->
            controller?.isSelected(scope.value)
        return
)

module.directive("tabsWithMenu", ['$document', 'OutsideElementClick',
###*
@argument {import('angular').IDocumentService} $document
@argument {import('./../../directives/outside-element-click.directive').IOutsideElementClick} OutsideElementClick
@returns {angular.IDirective<angular.IScope & {[x: string]: any}>}
###
($document, OutsideElementClick) ->
    restrict: "E"
    replace: true
    scope:
        tabs       : '='
        selected   : '='
        added      : '='
        removed    : '='
        dragged    : '='
        duplicated : '=?'
        maxlength  : '=?'
        selectTab  : '='
        save       : '='
        shared     : '='
        imported   : '='
        # Expected Format for tabs is an array of
            # {
            #     id: "Some Unique Id",
            #     name: "Tab Name"
            # }
        #
    template: \
    """
    <article class="ui-tabs-with-menu">
        <aside class="ui-tabs-actions ui-tabs-actions-left" ng-if="tabs && tabs.length > 0">
            <div class="new-import-button">
                <div class="left-side new-import-button-style"
                    ng-class="{'single': !imported }"
                    ng-click="addTab()">
                    <plus-micro class="plus-micro react-icon" model="'icon-12'"></plus-micro>
                    <span>New</span>
                </div>
                <div class="right-side new-import-button-style"
                    ng-if="imported"
                    ng-class="{'active': createTabToggleDropdown.isActive }"
                    ng-click="createTabToggleDropdown.toggle()">
                    <chevron-down-micro class="chevron-down-micro react-icon" model="'icon-12'"></chevron-down-micro>
                    <div class="create-tab-dropdown-container">
                        <div class="create-tab-dropdown-item"
                            ng-repeat="item in createTabDropdownItems"
                            ng-click="item.onClick($event)">{{ item.title }}
                        </div>
                    </div>
                </div>
            </div>
        </aside>
        <main class="ui-tabs-container">
            <ui-tabs selected="selected">
                <ui-tab
                    ng-repeat="model in tabs"
                    tab-value="model"
                    select-tab="selectTab">
                    <div class="icon-drag-container">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24">
                            <path fill="none" d="M0 0h24v24H0V0z"/><path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
                        </svg>
                    </div>
                    <div ng-double-click="setEditModeIndex($index)" ng-if="$index != editModeIndex" class="tab-inner-content">
                        {{ model.name }}
                    </div>
                    <input
                        class="tab-inner-content"
                        ng-if="$index == editModeIndex"
                        size="{{model.name.length > 0 ? model.name.length : 1}}"
                        type="text"
                        ng-enter="setEditModeIndex(-1);"
                        ng-esc="setEditModeIndex(-1);"
                        maxlength="{{maxLength}}"
                        ng-model="model.name"
                        focus-on-ng-if
                    />
                    <div class="icon-options-menu"
                        ng-click="toggleDropdown($index)"
                        ng-class="{'toggled': $index==dropDownIndex}">
                        <chevron-down-micro class="chevron-down-micro react-icon" model="'icon-16'" />
                    </div>
                </tab-v2>
            </ui-tabs>
            <div class="ui-tabs-actions-scroll" ng-show="shouldShowScroll()">
                <button class="ui-tab-action-scroll ui-tab-button" ng-click="scrollLeft()">
                    <chevron-left-micro class="react-icon" model="'light-grey icon-18'"></chevron-left-micro>
                </button>
                <button class="ui-tab-action-scroll ui-tab-button" ng-click="scrollRight()">
                    <chevron-right-micro class="react-icon" model="'light-grey icon-18'"></chevron-right-micro>
                </button>
            </div>
        </main>
        <div class="dropdown" style="position:fixed; width:{{dropDownRect.width}}px; top:{{dropDownRect.height}}px; left:{{dropDownRect.left}}px" ng-class="{open:dropDownIndex!=-1, close:dropDownIndex==-1}">
            <ul class="dropdown-menu">
                <li ng-click="setEditModeIndex(dropDownIndex)"> Rename </li>
                <li ng-if="duplicated != undefined" ng-click="duplicated(selected.id)"> Duplicate </li>
                <li ng-if="shared != undefined" ng-click="shared(selected.id); toggleOffAllEditModes();"> Share </li>
                <li ng-if="tabs.length > 1" class="delete" ng-click="removed(selected.id); toggleOffAllEditModes();"> Delete </li>
            </ul>
        </div>
    </article>
    """
    link: (scope, element) ->

        scope.addTab = ->
            scope.added()
            scope.scrollToEnd()

        scope.createTabToggleDropdown = new ToggleModel()
        scope.createTabDropdownItems = [
            {
                title: "Import",
                onClick: (event) ->
                    event?.preventDefault()
                    event?.stopImmediatePropagation()
                    scope.imported(event)
                    scope.createTabToggleDropdown.close()
            }
        ]

        unsubOutsideDropdownClick = ->
        scope.$watch 'createTabToggleDropdown.isActive', (isActive) ->
            unsubOutsideDropdownClick()
            if isActive
                unsubOutsideDropdownClick = OutsideElementClick scope, element.find('.right-side.new-import-button-style'), ->
                    scope.createTabToggleDropdown.close()


        scope.maxLength ?= 60

        uiTabsElement = do ->
            return element[0].getElementsByClassName('ui-tabs')[0]

        onUiTabsContainerScroll = ->
            scope.dropDownIndex = -1
            scope.$apply()
        $(uiTabsElement).on('scroll', onUiTabsContainerScroll)
        scope.$on '$destroy', -> $(uiTabsElement).off('scroll', onUiTabsContainerScroll)

        sortable = createSortable uiTabsElement.firstElementChild,
            ghostClass: 'ui-tab-placeholder'
            handle: '.icon-drag-container'
            onStart: (evt) ->
                evt?.stopImmediatePropagation()
                evt?.preventDefault()
                scope.dropDownIndex = -1
            onEnd: (evt) ->
                return if evt.oldIndex is evt.newIndex
                scope.dragged(evt.oldIndex, evt.newIndex)
                scope.$apply()
        scope.$on 'destroy', -> sortable.destroy()

        setDropDownRect = ->
            index = scope.dropDownIndex
            dropDownRect = element[0].getElementsByClassName('ui-tab')[index]?.getBoundingClientRect()
            return if not dropDownRect
            # 30 to account for the plus button
            if (dropDownRect.x < 30)
                element[0].getElementsByClassName('ui-tab')[0].scrollLeft = index * dropDownRect.width
                dropDownRect.x = 0
            scope.dropDownRect = dropDownRect

        scope.dropDownIndex = -1
        scope.editModeIndex = -1

        scope.fillIfNeeded =  ->
            if not scope.selected.name or scope.selected.name?.trim().length is 0
                scope.selected.name = 'New View'
            updateSelectedTabName()

        scope.$watch 'selected', ->
            scope.toggleOffAllEditModes(scope.selected)

        scope.$watch 'dropDownIndex', ->
            scope.editModeIndex = -1 if scope.dropDownIndex > -1

        updateSelectedTabName = do ->
            return _.debounce((-> scope.save?()), 500)

        OutsideElementClick scope, element.find('.ui-tabs > ul, .dropdown-menu'), ->
            scope.toggleOffAllEditModes()

        scope.shouldShowScroll = _.throttle(->
            return false if not scope.tabs or scope.tabs.length is 0
            scrollableEl = uiTabsElement.querySelector('ul')
            return (scrollableEl and ($(scrollableEl).width() > $(uiTabsElement).width()))
        , 100)

        scope.scrollLeft = ->
            currentScroll = uiTabsElement.scrollLeft
            currentScroll -= 200
            $(uiTabsElement).animate({scrollLeft: "+#{currentScroll}"}, 100, null)
            return

        scope.scrollRight = ->
            currentScroll = uiTabsElement.scrollLeft
            currentScroll += 200
            $(uiTabsElement).animate({scrollLeft: "+#{currentScroll}"}, 100, null)
            return

        scope.scrollToEnd = ->
            return if not scope.shouldShowScroll()
            scrollWidth = uiTabsElement.scrollWidth
            $(uiTabsElement).animate({scrollLeft: "+#{scrollWidth}"}, 100, null)
            return

        scope.toggleOffAllEditModes =  (selected) ->
            # Don't toggle off the dropdown if it was selected
            if selected
                indexOfSelected = scope.tabs.indexOf(selected)
                return if scope.dropDownIndex is indexOfSelected
            scope.editModeIndex = -1
            scope.dropDownIndex = -1

        scope.$on '$destroy', do ->
            onKeyPress = (event) ->
                isEscape = event.key is 'Escape' or (event.keyCode ? event.which) is 27
                scope.toggleOffAllEditModes() if isEscape
                return
            $document.on('keypress keydown', onKeyPress)
            return -> $document.off('keypress keydown', onKeyPress)

        scope.cancelTabsActions = () ->
            scope.toggleOffAllEditModes()

        scope.setEditModeIndex = (index) ->
            scope.fillIfNeeded()
            scope.editModeIndex = index
            scope.dropDownIndex = -1

        scope.toggleDropdown = (index) ->
            scope.dropDownIndex = do ->
                return -1 if index is scope.dropDownIndex
                return index
            setDropDownRect()

        return
])
