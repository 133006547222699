import _ from 'lodash';
import {
    ICreateMetricSelectorTreeParamsOptions,
    IMetricSelectorTreeInstance,
    createMetricSelect,
} from './metrics-selector-tree/metrics-selector-tree.builder';
import { IPropertyDefinition } from '../lib/config-hierarchy';
import { ToggleModel } from '../lib/model/model-toggle';
import { IMetricDefinition } from '../lib/types';
import { KeyboardObserverAngular } from '../lib/dom/keyboard-observer';
import { MouseObserverAngular } from '../lib/dom/mouse-observer';
import { IncludesFilter } from '../filters/includes.filter';

export class SidebarToggleModel extends ToggleModel {
    tab: string;
    constructor(options?: { tab?: string; isOpen?: boolean }) {
        super(options?.isOpen);
        this.tab = options?.tab ?? '';
    }

    override toggle(event?: Event, tab?: string) {
        if (!tab || (tab === this.tab && this.isOpen)) {
            this.tab = '';
            return super.close(event);
        }

        if (this.tab !== tab && this.isOpen) {
            this.tab = tab ?? this.tab;
            return super.open(event);
        }

        this.tab = tab ?? this.tab;
        return super.toggle(event);
    }

    override open(event?: Event, tab?: string) {
        this.tab = tab ?? this.tab;
        return super.open(event);
    }
}

export interface ISidebarPropertiesMenuModel {
    selected: IPropertyDefinition[] | undefined;
    available: IPropertyDefinition[];
    options?: {
        multipleSelection?: boolean;
        noSelection?: boolean;
        chevron?: boolean;
        allOpen?: boolean;
    };
    selectProperty: (property: IPropertyDefinition[]) => void;
}

interface SidebarPropertiesMenuScope extends angular.IScope {
    model: ISidebarPropertiesMenuModel;
    toggle: ToggleModel;
    onSearch: (value: string) => void;
    propertyGroups: PropertyGroups;
    toggles: Record<string, ToggleModel>;
    propertyClick: ($event: Event, property: IPropertyDefinition) => void;
    multiPropertyModel: {
        mouseOnTopOfElement: boolean;
        enabled: boolean;
    };
}

interface PropertyGroupsOptions {
    properties: IPropertyDefinition[];
    searchId: string;
    toggles?: Record<string, ToggleModel> | boolean | undefined;
    selectedId?: string[] | undefined;
    action: {
        onClick: (property: IPropertyDefinition) => void;
    };
    options: {
        chevron?: boolean;
    };
}

interface IPropertyGroupsProperty {
    id: string;
    label: string;
    selectable: boolean;
    selected: boolean;
    onClick: () => void;
    color: string;
    chevron: boolean;
}

class PropertyGroups {
    groups: { key: string; properties: IPropertyGroupsProperty[] }[];
    toggles: Record<string, ToggleModel>;
    selected: string[] = [];
    searchId: string;
    action: {
        onClick: (property: IPropertyDefinition) => void;
    };
    flags: {
        chevron: boolean;
    };
    constructor(options: PropertyGroupsOptions) {
        this.selected = options.selectedId ?? [];
        this.searchId = options.searchId;
        this.flags = {
            chevron: options.options.chevron ?? false,
        };
        this.groups = this.buildPropertyGroups(options.properties, options.searchId);
        this.toggles = this.buildToggles(options.toggles);
        this.action = options.action;
    }

    buildToggles(toggles: Record<string, ToggleModel> | boolean | undefined) {
        return this.groups.reduce<Record<string, ToggleModel>>((acc, group) => {
            const isOpen = (() => {
                if (typeof toggles === 'boolean') return toggles;
                return (
                    toggles?.[group.key]?.isActive ||
                    (Boolean(this.selected) && group.properties.some(property => this.selected.includes(property.id)))
                );
            })();
            acc[group.key] = new ToggleModel(isOpen);
            return acc;
        }, {});
    }

    buildPropertyGroups(properties: IPropertyDefinition[], searchId = '') {
        const groups = properties.reduce((acc: Record<string, IPropertyGroupsProperty[]>, property) => {
            const groupName = property.category?.label ?? property.label ?? property.table ?? '';

            const isSearchedGroup =
                searchId === '' ||
                groupName.toLowerCase().includes(searchId.toLowerCase()) ||
                (property.table ?? '').toLowerCase().includes(searchId.toLowerCase()) ||
                property.label.toLowerCase().includes(searchId.toLowerCase()) ||
                property.id.toLowerCase().includes(searchId.toLowerCase());

            if (!isSearchedGroup) return acc;

            acc[groupName] = acc[groupName] ?? [];
            const propertyItem: IPropertyGroupsProperty = {
                id: property.id,
                label: property.label,
                selectable: true,
                selected: this.selected.includes(property.id),
                onClick: (event?: MouseEvent) => {
                    event?.preventDefault();
                    event?.stopImmediatePropagation();
                    if (this.selected.length === 1 && this.selected.includes(property.id)) return;
                    propertyItem.selected = !propertyItem.selected;
                    this.action.onClick(property);
                },
                color: 'blue',
                chevron: this.flags.chevron,
            };
            acc[groupName]?.push(propertyItem);

            return acc;
        }, {});

        const result = [];
        for (const [key, properties] of Object.entries(groups)) {
            result.push({ key, properties });
        }

        return result;
    }

    updateSelectedIds(selectedIds: string[]) {
        this.selected = selectedIds;
        for (const group of this.groups) {
            for (const property of group.properties) {
                property.selected = selectedIds.includes(property.id);
            }
        }
    }
}

export const SidebarPropertiesMenuDirective = () => [
    function SidebarPropertiesMenu(): angular.IDirective<SidebarPropertiesMenuScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                toggle: '=',
            },
            replace: true,
            template: `
            <div class="sidebar-properties-menu">
                <list-search-box
                    ng-if="model.available.length > 0 && toggle.isActive"
                    on-search="onSearch">
                </list-search-box>
                <article class="metrics-funnel-breadcrumb">
                    <div class="property-group" ng-repeat="group in propertyGroups.groups"
                        ng-class="{'opened': propertyGroups.toggles[group.key].isActive || propertyGroups.searchId !== '' }">
                        <div class="property-group-header" sticky
                            ng-click="propertyGroups.toggles[group.key].toggle()">
                            <div class="property-group-open-close">
                                <chevron-down-micro class="react-icon" model="'icon-14'"></chevron-down-micro>
                            </div>
                            <div class="property-group-header-title">{{ group.key }}</div>
                        </div>
                        <div class="ui-pellets">
                            <selection-pebble
                                ng-repeat="x in group.properties"
                                model="x"
                                options="multiPropertyModel">
                            </selection-pebble>
                        </div>
                    </div>
                </article>
            </div>
            `,
            link: function SidebarPropertiesMenuLink($scope, element) {
                $scope.multiPropertyModel = {
                    mouseOnTopOfElement: false,
                    enabled: false,
                };

                if ($scope.model.options?.multipleSelection) {
                    const keyboardObserver = new KeyboardObserverAngular($scope, window);
                    keyboardObserver.onKeyPress((event: KeyboardEvent) => {
                        if ('key' in event && event.key !== 'Shift') {
                            $scope.multiPropertyModel.enabled = false;
                            return;
                        }
                        $scope.multiPropertyModel.enabled = event.type === 'keydown';
                    });

                    const mouseElementObserver = new MouseObserverAngular($scope, element[0], {
                        preventDefault: false,
                    });
                    mouseElementObserver.onMouseEnter(() => {
                        $scope.multiPropertyModel.mouseOnTopOfElement = true;
                    });
                    mouseElementObserver.onMouseLeave(() => {
                        $scope.multiPropertyModel.mouseOnTopOfElement = false;
                    });
                }

                const selectProperty = (property: IPropertyDefinition) => {
                    // Put condition for when pressing the same property and it's the only one in the list
                    if (
                        $scope.model.selected?.length === 1 &&
                        $scope.model.selected[0] &&
                        $scope.model.selected[0].id === property.id
                    )
                        return;

                    if ($scope.model.options?.noSelection) {
                        $scope.model.selectProperty([property]);
                        return;
                    }

                    const isMultiSelectionEnabled =
                        $scope.model.options?.multipleSelection &&
                        $scope.multiPropertyModel.enabled &&
                        $scope.multiPropertyModel.mouseOnTopOfElement;

                    if (isMultiSelectionEnabled) {
                        if (!$scope.model.selected?.find(s => s.id === property.id)) {
                            $scope.model.selected = [...($scope.model.selected ?? []), property];
                        } else {
                            $scope.model.selected = $scope.model.selected.filter(s => s.id !== property.id);
                        }
                    } else {
                        $scope.model.selected = [property];
                    }

                    $scope.propertyGroups.updateSelectedIds($scope.model.selected.map(x => x.id));
                    $scope.model.selectProperty($scope.model.selected);
                };

                let searchId = '';
                $scope.onSearch = (id: string) => {
                    searchId = id;
                    $scope.propertyGroups = new PropertyGroups({
                        properties: $scope.model.available,
                        searchId,
                        selectedId: $scope.model.selected ? getSelectedId($scope.model.selected) : [],
                        toggles: $scope.propertyGroups.toggles,
                        action: {
                            onClick: selectProperty,
                        },
                        options: {
                            chevron: Boolean($scope.model.options?.chevron),
                        },
                    });
                };

                $scope.$watch('model', () => {
                    if (!$scope.model.options?.multipleSelection) return;
                });

                $scope.$watch('toggle.isActive', (isActive: boolean) => {
                    if (isActive) searchId = '';
                });

                const getSelectedId = (selected: IPropertyDefinition[] | IPropertyDefinition) => {
                    const selectedId = Array.isArray(selected) ? selected : [selected];
                    return selectedId.map(x => x.id);
                };

                let unWatchAvailableProperties = () => {};
                $scope.$watch('model', () => {
                    unWatchAvailableProperties();
                    unWatchAvailableProperties = $scope.$watch(
                        'model.available',
                        (
                            available: IPropertyDefinition[] | undefined,
                            old: IPropertyDefinition[] | undefined,
                        ): void => {
                            if (!available) return;
                            const toggles = (() => {
                                if (old === available && typeof $scope.model.options?.allOpen === 'boolean') {
                                    return $scope.model.options.allOpen;
                                }
                                return $scope.propertyGroups?.toggles ?? {};
                            })();

                            $scope.propertyGroups = new PropertyGroups({
                                properties: available ?? [],
                                searchId,
                                selectedId: $scope.model.selected ? getSelectedId($scope.model.selected) : [],
                                toggles,
                                action: {
                                    onClick: selectProperty,
                                },
                                options: {
                                    chevron: Boolean($scope.model.options?.chevron),
                                },
                            });
                        },
                    );
                });
            },
        };
    },
];

interface ISidebarMetricsMenuModel {
    selected: string[];
    available: IMetricDefinition[];
    options: ICreateMetricSelectorTreeParamsOptions;
    selectMetrics: (selected: IMetricDefinition[]) => void;
}

interface SidebarMetricsMenuScope extends angular.IScope {
    model: ISidebarMetricsMenuModel;
    toggle: ToggleModel;
    onSearch: (value: string) => void;
    selectAll: () => void;
    reset: () => void;
}

export const SidebarMetricsMenuDirective = () => [
    function SidebarMetricsMenu(): angular.IDirective<SidebarMetricsMenuScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                toggle: '=',
            },
            replace: true,
            template: `
                <div class="sidebar-metrics-menu">
                    <list-custom-header
                        model="model"
                        on-search="onSearch"
                        select-all="selectAll"
                        reset="reset"
                        toggle="toggle"
                        item-label="metrics">
                    ></list-custom-header>
                    <main>
                        <div class="metric-selector-container">
                            <div class="metric-selector-tree"></div>
                        </div>
                    </main>
                </div>
            `,
            link: function SidebarMetricsMenuLink($scope, $element) {
                const $metricSelectElement = $($element).find('.metric-selector-tree');
                let metricSelect: null | IMetricSelectorTreeInstance = null;

                const onChangeSelectedMetrics = () => {
                    const metrics = metricSelect?.getSelected() ?? [];
                    if (_.isEqual(metrics, $scope.model.selected)) return;
                    let selectedMetrics = $scope.model.available.filter(x => metrics.includes(x.field));
                    selectedMetrics = _.sortBy(selectedMetrics, x => metrics.indexOf(x.field));
                    $scope.model.selectMetrics(selectedMetrics);
                };

                const reload = () => {
                    metricSelect?.destroy();
                    metricSelect = createMetricSelect({
                        element: $metricSelectElement,
                        model: _.cloneDeep({ selected: $scope.model.selected, available: $scope.model.available }),
                        options: $scope.model.options,
                        onChange: () => onChangeSelectedMetrics(),
                        onSearch: (value, node) => {
                            if (metricSelect?.isLeaf(node) && node.parent !== '#' && node.parents.length > 2)
                                return false;

                            return IncludesFilter.parse(node.text, value, false).length > 0;
                        },
                    });
                };

                $scope.selectAll = () => {
                    if (!metricSelect) return;
                    const visibleNodes = metricSelect.getVisibleNodes();
                    if (visibleNodes.length === $scope.model.available.length) {
                        metricSelect.selectAll();
                        onChangeSelectedMetrics();
                        return;
                    }

                    metricSelect.selectNodes(visibleNodes);
                    onChangeSelectedMetrics();
                };
                $scope.reset = () => {
                    metricSelect?.unselectAll();
                    onChangeSelectedMetrics();
                };

                $scope.onSearch = (value: string) => {
                    metricSelect?.showJsTreeIfHidden();
                    metricSelect?.setSearch(value);
                };

                $scope.$watch('model.selected', () => {
                    if (metricSelect) {
                        const selectedIds = metricSelect.getSelected();
                        if (_.isEqual(selectedIds, $scope.model.selected)) return;

                        const openedNodes = metricSelect.getAllOpenedNodes();
                        reload();
                        metricSelect.openNodes(openedNodes);
                    }
                });

                $scope.$watch('toggle.isActive', (isActive: boolean) => {
                    if (isActive) reload();
                });
                $scope.$on('$destroy', () => metricSelect?.destroy());
            },
        };
    },
];

interface SidebarModelOptions {
    hideTabs?: boolean;
    showMenuBar?: boolean;
    disableResize?: boolean;
}

interface SidebarModelArguments {
    toggle?: undefined | SidebarToggleModel;
    properties: ISidebarPropertiesMenuModel;
    displayBy?: ISidebarPropertiesMenuModel;
    metrics?: ISidebarMetricsMenuModel;
    options?: SidebarModelOptions;
}

export class SidebarModel {
    toggle: SidebarToggleModel;
    properties: ISidebarPropertiesMenuModel;
    displayBy: undefined | ISidebarPropertiesMenuModel;
    metrics: undefined | ISidebarMetricsMenuModel;
    options?: undefined | SidebarModelOptions;

    constructor({ toggle, properties, metrics, displayBy, options }: SidebarModelArguments) {
        this.properties = properties;
        this.displayBy = displayBy;
        this.metrics = metrics;
        this.options = options;
        this.toggle = (() => {
            if (toggle) return toggle;
            return new SidebarToggleModel({ tab: 'properties', isOpen: true });
        })();
    }
}

interface SidebarDirectiveScope extends angular.IScope {
    TABS: { id: string; label: string; svg?: string; icon?: string; title: string }[];
    model: SidebarModel;
    toggle: SidebarToggleModel;
    resizing: boolean;
    selectTab: (event: Event, tab: string) => void;
    toggleSidebar: (event: Event) => void;
    initResizeMode: () => void;
}
export const SidebarDirective = () => [
    '$document',
    function SidebarDirective($document: angular.IDocumentService): angular.IDirective<SidebarDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                toggle: '=',
            },
            replace: true,
            template: `
                <div class="dimensions-sidebar"
                    ng-class="{'closed': !model.toggle.isActive, 'light': model.toggle.tab !== 'properties'}">
                    <div class="dimensions-sidebar-bar" ng-if="model.options.showMenuBar">
                        <div class="sidebar-header-close" ng-click="toggleSidebar($event)">
                            <div class="sidebar-toggle-icon">
                                <chevron-left-micro class="react-icon" model="'icon-14'"></chevron-left-micro>
                            </div>
                        </div>
                        <div class="sidebar-bar-tab-icons">
                            <div class="sidebar-tab-icon"
                                ng-repeat="tab in TABS"
                                title="{{tab.title}}"
                                ng-class="{
                                    'sidebar-svg': tab.svg,
                                    'sidebar-icon': tab.icon,
                                    'active': tab.id === model.toggle.tab
                                }"
                                ng-if="model[tab.id]"
                                ng-click="selectTab($event, tab.id)"
                            >
                                <generic-icon icon="tab.icon" ng-if="tab.icon"></generic-icon>
                            </div>
                        </div>
                    </div>
                    <div class="dimensions-sidebar-content">
                        <div class="sidebar-header" ng-if="!model.options.hideTabs">
                            <div class="sidebar-header-tabs">
                                <div
                                    ng-repeat="tab in TABS"
                                    ng-if="model[tab.id] && tab.id === model.toggle.tab"
                                    ng-click="selectTab(tab.id)"
                                    class="sidebar-header-tab"
                                    ng-class="{'active': tab.id === model.toggle.tab}">
                                    <div class="sidebar-header-title">{{ tab.label }}</div>
                                </div>
                            </div>
                        </div>
                        <sidebar-properties-menu
                            ng-if="model.properties"
                            ng-hide="model.toggle.tab !== 'properties'"
                            model="model.properties"
                            toggle="model.toggle">
                        </sidebar-properties-menu>
                        <sidebar-properties-menu
                            ng-if="model.displayBy"
                            ng-hide="model.toggle.tab !== 'displayBy'"
                            model="model.displayBy"
                            toggle="model.toggle">
                        </sidebar-properties-menu>
                        <sidebar-metrics-menu
                            ng-if="model.metrics && model.toggle.tab === 'metrics'"
                            model="model.metrics"
                            toggle="model.toggle">
                        </sidebar-metrics-menu>
                    </div>
                    <div
                        class="sidebar-resizer-bar"
                        ng-if="!model.options.disableResize"
                        ng-class="{ 'active': resizing }"
                        ng-mousedown="initResizeMode($event)">
                    </div>
                </div>
            `,
            link: function sidebarLink($scope, $element) {
                const RE_SIZING_CLASS = 'resizing';
                const $app = $document.find('#app');
                const $dimensionsSidebarBarElement = $element.find('.dimensions-sidebar-bar');
                const $dimensionsSidebarContentElement = $element.find('.dimensions-sidebar-content');

                const endResizeMode = () => {
                    $app.removeClass(RE_SIZING_CLASS);
                    $scope.resizing = false;
                    $document.off<'mousemove'>('mousemove', onResizeMove);
                    $document.off('mouseup', endResizeMode);
                };
                const onResizeMove = _.throttle((event: JQuery.Event) => {
                    if (!$dimensionsSidebarContentElement[0]) return;
                    const { left, width } = $dimensionsSidebarContentElement[0].getBoundingClientRect();
                    const { width: sidebarBarWidth } = $dimensionsSidebarBarElement[0]?.getBoundingClientRect() ?? {
                        width: 0,
                    };
                    const { clientX, clientY } = event;
                    if (clientY === undefined || clientX === undefined) {
                        endResizeMode();
                        return;
                    }

                    const newWidth = clientX - left;
                    if (newWidth < 272) {
                        if (width !== 272) {
                            $element.css({ width: `${sidebarBarWidth + 272}px` });
                        }
                        return;
                    }
                    $element.css({ width: `${sidebarBarWidth + newWidth}px` });
                }, 10);

                $scope.initResizeMode = () => {
                    if (!$app.hasClass(RE_SIZING_CLASS)) $app.addClass(RE_SIZING_CLASS);
                    $scope.resizing = true;
                    $document.on('mousemove', onResizeMove);
                    $document.on('mouseup', endResizeMode);
                };

                $scope.TABS = [
                    {
                        id: 'properties',
                        label: 'Group By',
                        title: 'Group By Properties',
                    },
                    {
                        id: 'displayBy',
                        label: 'Display By',
                        title: 'Display By Properties',
                    },
                    {
                        id: 'metrics',
                        label: 'Edit Metrics',
                        icon: 'icon-flow-cascade',
                        title: 'Selected Metrics',
                    },
                ];

                $scope.toggleSidebar = (event: Event) => {
                    const tab = (() => {
                        if ($scope.model.toggle.isActive) return undefined;
                        return !_.isNil($scope.model.properties) ? 'properties' : 'metrics';
                    })();
                    $scope.model.toggle.toggle(event, tab);
                };

                $scope.selectTab = (event: Event, tabId: string) => {
                    const tab = $scope.model.toggle.tab !== tabId ? tabId : undefined;
                    $scope.model.toggle.toggle(event, tab);
                };

                $scope.$on('$destroy', () => {
                    if ($app.hasClass(RE_SIZING_CLASS)) $app.removeClass(RE_SIZING_CLASS);
                });
            },
        };
    },
];

const SidebarModule = angular
    .module('42.components.sidebar', [])
    .directive('sidebar', SidebarDirective())
    .directive('sidebarPropertiesMenu', SidebarPropertiesMenuDirective())
    .directive('sidebarMetricsMenu', SidebarMetricsMenuDirective());

export default SidebarModule;
