import moment from 'moment'

import { ToggleModel } from '../../lib/model/model-toggle'
import { getComparisonModes } from './lib/datepicker-comparison-mode.model'
import { ShadowDomDirectiveWrapper } from '../../lib/angular/directive-shadow-dom'
import DatepickerCSS from './datepicker.module.scss'
import { getDatepickerCalendarMonthModel } from './view/datepicker-calendar-month.model'

module = angular.module('42.modules.datepicker', [])
export default module


module.filter 'calendarDate', [() -> (date, format = 'MMM DD, YYYY') ->
    date = moment.utc(date) if typeof date is 'string'
    return (try date.format(format)) or ''
]

module.filter 'calendarDates', [() -> (timerange) ->
    return '' if not timerange or not timerange.start

    start = timerange.start
    start = moment.utc(timerange.start) if typeof timerange.start is 'string'
    end = timerange.end
    end = moment.utc(timerange.end) if typeof timerange.end is 'string'

    return "#{start.format('MMM DD')} - #{end.format('MMM DD, YYYY')}"
]


module.directive 'datepickerTimerangeDisplay', [() ->
    restrict: 'E'
    scope:
        model: '='
        format: '@'
        fullDescription: '='
    replace: true
    template: \
    """
    <div class="datepicker-timerange-display" ng-class="{'full-description': fullDescription}">
        <span class="timerange timerange-selection" ng-if="view.selection && (!view.isSelectionSameYear || fullDescription)">
            <span class="selection-label">TY</span>
            <span class="time selection-start">{{ view.selection.start | calendarDate:format }}</span>
            to
            <span class="time selection-end">{{ view.selection.end | calendarDate:format }}</span>
        </span>
        <span class="timerange timerange-selection" ng-if="view.selection && view.isSelectionSameYear && !fullDescription">
            <span class="selection-label">TY</span>
            <span class="time selection-start">{{ view.selection | calendarDates }}</span>
        </span>
        <div class="separator" ng-if="view.comparison">{{ fullDescription ? 'versus' : '-' }}</div>
        <span class="timerange timerange-comparison" ng-if="view.comparison && (!view.isComparisonSameYear || fullDescription)">
            <span class="selection-label">LY</span>
            <span class="time comparison-start">{{ view.comparison.start | calendarDate:format }}</span>
            to
            <span class="time comparison-end">{{ view.comparison.end | calendarDate:format }}</span>
        </span>
        <span class="timerange timerange-comparison" ng-if="view.comparison && view.isComparisonSameYear && !fullDescription">
            <span class="selection-label">LY</span>
            <span class="time comparison-end">{{ view.comparison | calendarDates }}</span>
        </span>
    </div>
    """
    link: (scope, element, attributes) ->
        attributes.$observe 'format', (format) -> scope.format = format ? 'MMM DD, YYYY'
        scope.view = {}

        updateView = ->
            scope.view = do ->
                return {} if not scope.model
                selection = scope.model.selection.selected
                comparison = scope.model.comparison.selected

                isSelectionSameYear = selection.start.get('year') is selection.end.get('year')

                if not comparison
                    isComparisonSameYear = false
                else
                    isComparisonSameYear = comparison?.start.get('year') is comparison?.end.get('year')

                label = scope.model.label
                return {selection, comparison, label, isSelectionSameYear, isComparisonSameYear}

        scope.$watch 'model.selection.selected', updateView
        scope.$watch 'model.comparison.selected', updateView
]


module.directive 'calendarPopupToggleButton', [() ->
    restrict: 'E'
    scope:
        model: '='
        format: '@'
    replace: true
    template: \
    """
    <div class="calendar-popup-toggle-button">
        <calendar-date-range class="calendar-icon react-icon" model="calendarIconModel"></calendar-date-range>
        <div class="timerange">
            <span class="timerange-start">{{ timerange.start | calendarDate:format }}</span>
            <span class="timerange-end">{{ timerange.end | calendarDate:format }}</span>
        </div>
    </div>
    """
    link: (scope, element, attributes) ->
        scope.calendarIconModel = { className: 'icon-16' }
        attributes.$observe 'format', (format) ->
            scope.format = format ? 'MMM DD, YYYY'
        scope.$watch 'model.state.ref', ->
            scope.timerange = do ->
                return if not scope.model
                return scope.model.getDatepicker().getSelection()
]


module.directive 'calendar', ['$compile', '$window', 'OutsideElementClick', ($compile, $window, OutsideElementClick) -> ShadowDomDirectiveWrapper $compile,
    restrict: 'E'
    scope:
        draft: "="
        onSave: "&"
        onCancel: "&"
    replace: true
    style: DatepickerCSS
    template: \
    """
    <article class="calendar">
        <header class="hide-small">
            <div class="calendar-type-switch-container" ng-if="draft.datepickers && draft.datepickers.view.available.length > 1">
                <button class="button-calendar-type"
                    ng-class="{open: calendarDropdown.isActive}"
                    ng-click="calendarDropdown.toggle($event)">
                    <div class="calendar-type-label">{{ draft.datepicker.label }}</div>
                    <chevron-down class="react-icon" model="'icon-16'"></chevron-down>
                </button>
                <div class="calendar-menu-dropdown" ng-class="{'opened': calendarDropdown.isActive}">
                    <div class="calendar-option"
                        ng-repeat="datepicker in draft.datepickers.view.available"
                        ng-class="{ selected: datepicker.id === draft.datepickers.view.selected.id }"
                        ng-click="onCalendarClick($event, datepicker.model)">
                        {{ datepicker.model.label }}
                    </div>
                </div>
            </div>

            <datepicker-timerange-display
                full-description="!(draft.datepickers && draft.datepickers.view.available.length > 1)"
                model="draft.datepicker">
            </datepicker-timerange-display>

            <div class="buttons">
                <button class="button-cancel" ng-click="onCancel({draft:draft})">cancel</button>
                <button class="button-save" ng-click="onSave({draft:draft})">save</button>
            </div>
        </header>

        <main>

            <section class="panel-left">

                <div class="datepicker-comparison-mode-selector">
                    <label>
                        <span>Compare to</span>
                        <select class="compare-selector"
                            ng-model="comparisonMode.modeId"
                            ng-options="x.id as x.shortLabel for x in comparisonMode.modes"
                        >
                        </select>
                    </label>
                </div>

                <ul class="datepicker-actions">
                    <li class="datepicker-action-container"
                        ng-if="!action.hide"
                        ng-repeat="action in draft.datepicker.actions"
                        ng-click="onActionClick($event, action)"
                        ng-class="{
                            active:   draft.datepicker.actionId == action.id,
                            disabled: action.disabled,
                            section:  action.section
                        }">
                        <div class="datepicker-action">
                            <span class="action-label" ng-bind-html="action.label"></span>
                        </div>
                    </li>
                </ul>

            </section>

            <section class="panel-right hide-small">
                <datepickers
                    class="datepickers-selection"
                    title="current"
                    model="draft.datepicker"
                    month-views="selectionMonthViews"
                    on-timerange-changed="draft.datepicker.setSelection(timerange)"
                    timerange="draft.datepicker.selection.selected">
                </datepickers>
                <datepickers
                    class="datepickers-comparison"
                    title="compare to"
                    model="draft.datepicker"
                    month-views="comparisonMonthViews"
                    on-timerange-changed="draft.datepicker.setComparison(timerange)"
                    timerange="draft.datepicker.comparison.selected">
                </datepickers>
            </section>
        </main>
    </article>
    """
    link: (scope, shadowRoot) ->
        scope.calendarDropdown = new ToggleModel()
        scope.selectionMonthViews =
            start: getDatepickerCalendarMonthModel(scope.draft.datepicker.view)
            end:   getDatepickerCalendarMonthModel(scope.draft.datepicker.view)
        scope.comparisonMonthViews =
            start: getDatepickerCalendarMonthModel(scope.draft.datepicker.view)
            end:   getDatepickerCalendarMonthModel(scope.draft.datepicker.view)

        scope.onCalendarClick = (event, DatepickerModel) ->
            event.preventDefault()
            event.stopImmediatePropagation()
            return if not DatepickerModel
            scope.draft.setCalendar(DatepickerModel.calendarId)
            return

        scope.onActionClick = (event, action) ->
            event.preventDefault()
            event.stopImmediatePropagation()
            scope.draft.datepicker.setAction(action.id)
            scope.onSave({draft: scope.draft}) if $window.innerWidth <= 768 # FIXME: this is a hack for when the widget is small on phones...
            return

        scope.comparisonMode =
            modeId: null,
            modes: getComparisonModes()

        scope.$watch 'comparisonMode.modeId', (modeId) ->
            return if not modeId
            mode = scope.draft?.datepicker.setComparisonMode(modeId)
            scope.comparisonMode.modeId = mode.id

        scope.$watch 'draft.datepicker.comparisonMode.mode.id', (modeId) ->
            scope.comparisonMode.modeId = modeId ? null

        scope.$watch 'draft.datepicker.id', ->
            scope.calendarDropdown.close()
            CalendarDropdownOutsideClickAction.cleanup()

        CalendarDropdownOutsideClickAction = do ->
            cleanup = ->
            cleanup: ->
                cleanup()
            create: ->
                cleanup()
                calendarSwitchElement = shadowRoot.querySelector('.calendar-type-switch-container')
                cleanup = OutsideElementClick scope, calendarSwitchElement, ->
                    scope.calendarDropdown.close()
                return

        scope.$watch 'calendarDropdown.isActive', (isActive) ->
            CalendarDropdownOutsideClickAction.cleanup() if not isActive
            CalendarDropdownOutsideClickAction.create() if isActive
]

module.directive 'smartGroupsFilterTime', ($document, OutsideElementClick) ->
    restrict: 'E'
    scope:
        model:  "="
    replace: true
    template: \
    """
    <article class="smart-groups-filter-time">

        <article class="smart-group-filter-time-panel">
            <calendar-popup-toggle-button
                model="model"
                ng-class="{active: view.popup.isActive}"
                ng-click="toggle()"
            ></calendar-popup-toggle-button>
        </article>

        <article class="calendar-popup" ng-class="{active: view.popup.isActive}">
            <calendar
                ng-if="view.draft.datepicker.view"
                draft="view.draft"
                on-cancel="close()"
                on-save="save(draft)"
            ></draft>
        </article>

    </article>
    """
    link: (scope, element) ->
        scope.view = {state: null, draft: null, popup: new ToggleModel()}

        scope.save = (draft) ->
            console.log("saving draft calendar:", draft)
            scope.model.setState(draft)
            scope.close()

        scope.close = ->
            scope.view.draft = null
            scope.view.popup.close()
            return

        scope.open = ->
            scope.view.state = scope.model.getState()
            scope.view.draft = scope.model.getDraft()
            scope.view.popup.open()
            return

        scope.toggle = ->
            scope.view.popup.toggle()
            isActive = scope.view.popup.isActive
            if isActive then scope.open() else scope.close()
            return

        DatepickerOutsideClickAction = do ->
            cleanup = null
            cleanup: ->
                cleanup?()
            create: ->
                cleanup?()
                cleanup = OutsideElementClick scope, element[0], -> scope.close()
                return

        closeOnEscapeKey = (event) ->
            isEscapeKey = event.key is 'Escape' or (event.keyCode ? event.which) is 27
            scope.close() if isEscapeKey
            return

        escapeKeyWatcherCleanup = ->
            $document.off('keypress keydown', closeOnEscapeKey)

        scope.$watch 'view.popup.isActive', (isActive) ->
            DatepickerOutsideClickAction.cleanup()
            escapeKeyWatcherCleanup()
            if isActive
                # wait for the element to render
                setTimeout((-> DatepickerOutsideClickAction.create()), 0)
                $document.on('keypress keydown', closeOnEscapeKey)

        scope.$watch 'model', (model) ->
            return scope.close() if not model
            model.setDraft(scope.view.draft) if scope.view.draft
            scope.view.draft = model.getDraft()

        scope.$on('$destroy', escapeKeyWatcherCleanup)
        return



module.directive 'datepickers', ->
    restrict: 'E'
    scope:
        title: '@'
        model: '='
        timerange: '='
        onTimerangeChanged: '&'
        monthViews: '='
    replace: true
    template: \
    """
    <article class="datepickers-container">
        <section class="date-pickers" ng-if="monthViews">
            <article class="date-picker">
                <datepicker-date-input
                    label='start date'
                    date='timerange.start'
                    on-changed='onSelectedStartInput(date)'
                ></datepicker-date-input>
                <datepicker-month-select model="monthViews.start"></datepicker-month-select>
                <datepicker-month class="datepicker-start"
                    month="monthViews.start.month"
                    on-select-week="onSelectWeek(week)"
                    on-select-date="onSelectStart(date)"
                    timerange="timerange"
                ></datepicker-month>
            </article>
            <article class="date-picker">
                <datepicker-date-input
                    label='end-date'
                    date='timerange.end'
                    on-changed='onSelectedEndInput(date)'
                ></datepicker-date-input>
                <datepicker-month-select model="monthViews.end"></datepicker-month-select>
                <datepicker-month class="datepicker-end"
                    month="monthViews.end.month"
                    on-select-week="onSelectWeek(week)"
                    on-select-date="onSelectEnd(date)"
                    timerange="timerange"
                ></datepicker-month>
            </article>
        </section>
    </article>
    """
    link: (scope) ->

        scope.onSelectedEndInput = (date) ->
            date = try scope.model.getDate(date) if date
            return if not date
            scope.onSelectEnd(date)

        scope.onSelectedStartInput = (date) ->
            date = try scope.model.getDate(date) if date
            return if not date
            scope.onSelectStart(date)

        scope.onSelectEnd = (date) ->
            return if not date
            timerange = {end:date, start:scope.timerange?.start}
            timerange.start = timerange.end.clone() if not timerange.start or timerange.end.lt(timerange.start)
            scope.onTimerangeChanged?({timerange})
            return

        scope.onSelectStart = (date) ->
            return if not date
            timerange = {start:date, end:scope.timerange?.end}
            timerange.end = timerange.start.clone() if not timerange.end or timerange.start.gt(timerange.end)
            scope.onTimerangeChanged?({timerange})
            return

        scope.onSelectWeek = (week) ->
            start = week[0] or null
            end = week[week.length-1]
            return if not (start and end)
            start ?= scope.timerange.start
            end ?= scope.timerange.end
            scope.onTimerangeChanged?({timerange:{start, end}})
            return

        scope.$watch 'model.view', (view) ->
            return if not view
            scope.monthViews =
                start: getDatepickerCalendarMonthModel(view)
                end:   getDatepickerCalendarMonthModel(view)
            return

        scope.$watch 'timerange.start', (date) ->
            return if not date
            scope.monthViews?.start?.selectFromDate(date)
            return

        scope.$watch 'timerange.end', (date) ->
            return if not date
            scope.monthViews?.end?.selectFromDate(date)
            return


module.directive 'datepickerDateInput', ->
    restrict: 'E'
    scope:
        label: '@'
        model: '=date'
        onChanged: '&'
    replace: true
    template: \
    """
    <article class="date-input">
        <label>{{ label }}</label>
        <input ng-model="value">
    </article>
    """
    link: (scope, element) ->
        inputEl = element[0].getElementsByTagName('input')[0]

        isValid = (value) ->
            return false if typeof value isnt 'string'
            return false if not /^\d\d\d\d-\d\d-\d\d$/.test(value)
            return true

        getViewFromModel = (model) ->
            value = try model?.format('YYYY-MM-DD')
            return value

        updateViewFromModel = (model) ->
            scope.value = getViewFromModel(model)
            return

        updateModelFromView = (value) ->
            return if not isValid(value)
            return if value is getViewFromModel(scope.model)
            scope.onChanged?({date:value})

        scope.value = ''
        scope.$watch('value', updateModelFromView)
        scope.$watch('model', updateViewFromModel)

        onFocusOut = ->
            updateViewFromModel(scope.model)
            scope.$apply()
            return

        inputEl.addEventListener('focusout', onFocusOut)
        scope.$on('$destroy', () -> inputEl.removeEventListener('focusout', onFocusOut))



module.directive 'datepickerMonth', ->
    restrict: 'E'
    scope:
        month:        '='
        onSelectDate: '&'
        onSelectWeek: '&'
        timerange:    '='
    replace: true
    template: \
    """
    <div class="datepicker-month">
        <div class="datepicker-weeks">
            <div class="datepicker-week" ng-repeat="week in month.weeks track by $index">
                <span class="datepicker-week-number" ng-click="onSelectWeek({week:week})">W{{ week.offset + 1 }}</span>
                <div class="datepicker-day"
                    ng-repeat="date in week track by $index"
                    ng-class="{
                        'state-selected-start': timerange.start && date.eq(timerange.start)
                    ,   'state-selected-end':   timerange.end   && date.eq(timerange.end)
                    ,   'state-in-range':       timerange.start && timerange.end && date.within(timerange.start, timerange.end)
                    ,   'state-disabled':       date === null
                    ,   'state-disabled-last':  date === null && week[$index + 1] !== null
                    }"
                    ng-click="onSelectDate({date:date})">
                    <span class="date-month">{{ date.format('MMM') }}</span>
                    <span class="date-day">{{ date.format('DD')}}</span>
                </div>
            </div>
            <div class="datepicker-week" ng-if="month.weeks.length === 4">
                <span class="datepicker-week-number"></span>
                <div class="datepicker-day state-blank"></div>
                <div class="datepicker-day state-blank"></div>
                <div class="datepicker-day state-blank"></div>
                <div class="datepicker-day state-blank"></div>
                <div class="datepicker-day state-blank"></div>
                <div class="datepicker-day state-blank"></div>
                <div class="datepicker-day state-blank"></div>
            </div>
        </div>
    </div>
    """


module.directive 'datepickerMonthSelect', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="calendar-month-select">
        <button class="select-prev" ng-click="model.prev()" ng-disabled="!model.month.prev">
            <chevron-left class="react-icon" model="'icon-22'"></chevron-left>
        </button>

        <div class="selects">
            <select class="select-year" ng-model="model.selected.year"
                ng-options="model.getYearLabel(index) for index in model.ranges.year"></select>

            <select class="select-month" ng-model="model.selected.month"
                ng-options="model.getMonthLabel(index) for index in model.ranges.month"></select>
        </div>

        <button class="select-next" ng-click="model.next()" ng-disabled="!model.month.next">
            <chevron-right class="react-icon" model="'icon-22'"></chevron-right>
        </button>
    </article>
    """
    link: (scope) ->
        update = ->
            return if not scope.model
            scope.model.update()
        scope.$watch 'model.selected.month', update
        scope.$watch 'model.selected.year', update
